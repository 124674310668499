import { useField } from 'react-final-form';

import { Link, Text } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { EmployerStateType, isDiscardEmployerState } from 'src/models/negotiationTopic.types';

const MAIL_TEMPLATE_ID_FIELD = 'employerManagerTemplateId';

const TrlKeys = {
    messageDesc: 'employer.vacancyresponses.funnel.stateSettingsModal.messageDescription',
    edit: 'employer.vacancyresponses.funnel.stateSettingsModal.messageDescription.edit',
    create: 'employer.vacancyresponses.funnel.stateSettingsModal.messageDescription.create',
    messageDescDefaultDiscard: 'employer.vacancyresponses.funnel.stateSettingsModal.default.discard.messageDescription',
    messageDescDefault: 'employer.vacancyresponses.funnel.stateSettingsModal.default.messageDescription',
};

interface MailTemplateDescription {
    employerState: EmployerStateType;
    linkToEditTemplate: string;
    linkToCreateTemplate: string;
    closeModal: VoidFunction;
}

const MailTemplateDescription: TranslatedComponent<MailTemplateDescription> = ({
    trls,
    employerState,
    linkToEditTemplate,
    linkToCreateTemplate,
    closeModal,
}) => {
    const { input: templateId } = useField<number | null>(MAIL_TEMPLATE_ID_FIELD);
    const isDiscardState = isDiscardEmployerState(employerState);

    const descriptionDefaultTextByState = isDiscardState
        ? trls[TrlKeys.messageDescDefaultDiscard]
        : trls[TrlKeys.messageDescDefault];

    const descriptionCommon = trls[TrlKeys.messageDesc];

    const descriptionText = !templateId.value ? descriptionDefaultTextByState : descriptionCommon;

    return (
        <Text typography="label-3-regular" style="secondary">
            {formatToReactComponent(descriptionText, {
                '{0}': (
                    <Link
                        Element={SPALink}
                        to={`${linkToEditTemplate}&id=${templateId.value}`}
                        onClick={closeModal}
                        target="_blank"
                        inline
                        typography="label-3-regular"
                    >
                        {trls[TrlKeys.edit]}
                    </Link>
                ),
                '{1}': (
                    <Link
                        Element={SPALink}
                        to={linkToCreateTemplate}
                        onClick={closeModal}
                        target="_blank"
                        inline
                        typography="label-3-regular"
                    >
                        {trls[TrlKeys.create]}
                    </Link>
                ),
            })}
        </Text>
    );
};

export default translation(MailTemplateDescription);
