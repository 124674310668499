import { Button } from '@hh.ru/magritte-ui';
import { AdjustmentsOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

interface UpdateSubstateButtonProps {
    hideLabel?: boolean;
    onClick: () => void;
}

const TrlKeys = {
    settings: 'employer.vacancyresponses.funnel.settings',
};

const UpdateSubstateButton: TranslatedComponent<UpdateSubstateButtonProps> = ({ trls, hideLabel, onClick }) => (
    <Button
        size="small"
        style="neutral"
        mode="tertiary"
        onClick={onClick}
        icon={<AdjustmentsOutlinedSize16 />}
        hideLabel={hideLabel}
    >
        {trls[TrlKeys.settings]}
    </Button>
);

export default translation(UpdateSubstateButton);
