import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Field as FinalField, Form as FinalForm, useField, useForm } from 'react-final-form';
import classnames from 'classnames';
import { Config } from 'final-form';

import {
    Action,
    ActionBar,
    Banner,
    BottomSheet,
    BottomSheetFooter,
    Button,
    Cell,
    CellText,
    Checkbox,
    Loader,
    Modal,
    NavigationBar,
    Text,
    useBreakpoint,
} from '@hh.ru/magritte-ui';
import { CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import defaultRequestErrorHandler from 'src/api/notifications/defaultRequestErrorHandler';
import useAddressLoading from 'src/components/AddressSuggest/hooks/useAddressLoading';
import { getEmployerTemplateLinks } from 'src/components/EmployerMailTemplates/utils/getEmployerTemplateLinks';
import Form from 'src/components/Form';
import { VSpacing } from 'src/components/MagritteRedesignComponents/Spacing';
import { useNotification } from 'src/components/Notifications/Provider';
import SegmentedControl from 'src/components/SegmentedControl';
import useFetchTemplatesByEmployerState from 'src/components/VacancyFunnel/api/useFetchTemplatesByEmployerState';
import { StageSettingsModalState } from 'src/components/VacancyFunnel/types';
import { getInitialTemplateId, getInitialTemplateText } from 'src/components/VacancyFunnel/utils/getInitialTemplate';
import processTemplates, {
    StateToMailTemplateType,
    StateToSmsTemplateType,
} from 'src/components/VacancyFunnel/utils/processTemplates';
import translation from 'src/components/translation';
import useFeatureEnabled from 'src/hooks/useFeatureEnabled';
import { useSelectorNonNullable } from 'src/hooks/useSelector';
import {
    ProcessedManagerTemplate,
    STATE_TO_TEMPLATE_MAIL,
    STATE_TO_TEMPLATE_SMS,
} from 'src/models/employerMailTemplates';
import { EmployerStateType } from 'src/models/negotiationTopic.types';
import { StageSettingsModalFormData, VacancyFunnelStage, VacancyFunnelSubstate } from 'src/models/vacancyFunnel';

import AddressField from 'src/components/VacancyFunnel/StageSettingsModal/AddressField';
import SmsTemplateField from 'src/components/VacancyFunnel/StageSettingsModal/SmsTemplateField';
import SubstateNameField from 'src/components/VacancyFunnel/StageSettingsModal/SubstateNameField';
import TemplateField from 'src/components/VacancyFunnel/StageSettingsModal/TemplateField';

import styles from './index.less';

interface StageSettingsModalProps {
    subtitle?: string;
    isVisible: boolean;
    toggleVisibility: () => void;
    modalType: StageSettingsModalState;
    formId: string;
    stateName: EmployerStateType;
    invalid: boolean;
    isAddressLoading: boolean;
    mailTemplates: ProcessedManagerTemplate[];
    smsTemplates: ProcessedManagerTemplate[];
    isTemplatesLoading: boolean;
    ownerEmployerManagerId: string;
}

const SUBSTATE_MODAL_TYPES = [StageSettingsModalState.SubstateEditing, StageSettingsModalState.SubstateCreating];

const TrlKeys = {
    header: {
        [StageSettingsModalState.StateEditing]:
            'employer.vacancyresponses.funnel.stateSettingsModal.header.stateEditing',
        [StageSettingsModalState.SubstateCreating]:
            'employer.vacancyresponses.funnel.stateSettingsModal.header.substateCreating',
        [StageSettingsModalState.SubstateEditing]:
            'employer.vacancyresponses.funnel.stateSettingsModal.header.substateEditing',
    },
    sms: 'employer.vacancyresponses.funnel.stateSettingsModal.sms',
    address: 'employer.vacancyresponses.funnel.stateSettingsModal.address',
    message: 'employer.vacancyresponses.funnel.stateSettingsModal.message',
    sendSms: 'employer.vacancyresponses.funnel.stateSettingsModal.sendSms',
    save: 'employer.vacancyresponses.funnel.stateSettingsModal.save',
    banner: 'employer.vacancyresponses.funnel.stateSettingsModal.forAllVacancies.banner',
    oneClickCheckbox: {
        state: 'employer.vacancyresponses.funnel.stateSettingsModal.oneClick.checkbox',
        substate: 'employer.vacancyresponses.funnel.stateSettingsModal.oneClick.checkbox.substate',
    },
};

const Features = {
    newMailTemplatesEnabled: 'new_mail_templates_for_funnel_enabled',
};

const FIELDS_TO_SECTION_MAP: Record<string | number, string> = {
    template: 'message',
    smsTemplate: 'sms',
    addressId: 'address',
};

const StageSettingsModal: TranslatedComponent<StageSettingsModalProps> = ({
    trls,
    subtitle,
    isVisible,
    toggleVisibility,
    modalType,
    formId,
    stateName,
    isAddressLoading,
    mailTemplates,
    smsTemplates,
    isTemplatesLoading,
    ownerEmployerManagerId,
}) => {
    const isSubstate = SUBSTATE_MODAL_TYPES.includes(modalType);
    const stage = useSelectorNonNullable((state) =>
        state.vacancyFunnel.stages?.find((stage) => stage.state === stateName)
    );
    const { getState, reset } = useForm();
    const { submitting } = getState();
    const { input: sendMessage } = useField<boolean>('sendMessage', { subscription: { value: true } });
    const variables = useSelectorNonNullable((state) => state.employerMailTemplates.variables);
    const sections = useMemo(() => {
        const result: Record<string, string> = {
            message: trls[TrlKeys.message],
        };
        if (stage?.templateInfo.addressAllowed) {
            result.address = trls[TrlKeys.address];
        }
        if (stage?.templateInfo.smsAllowed) {
            result.sms = trls[TrlKeys.sms];
        }
        return result;
    }, [stage, trls]);
    const [section, setSection] = useState<keyof typeof sections>('message');
    const { isMobile } = useBreakpoint();
    const filteredVariables = useMemo(
        () =>
            variables.filter((variable) => {
                if (section === 'address') {
                    return false;
                }

                return section === 'sms'
                    ? variable.visibility.sms
                    : ['COMMON', 'BOTH'].includes(variable.visibility.visibility);
            }),
        [variables, section]
    );

    const closeModal = useCallback(() => {
        reset();
        toggleVisibility();
    }, [reset, toggleVisibility]);

    const currentEmployerManagerId = useSelectorNonNullable((state) => state.employerManager.id);
    const isGuest =
        ownerEmployerManagerId && currentEmployerManagerId && ownerEmployerManagerId !== currentEmployerManagerId;

    const { smsLinks, mailLinks } = useMemo(() => {
        const templateType = STATE_TO_TEMPLATE_MAIL[stage?.state as StateToMailTemplateType];
        const smsTemplateType = STATE_TO_TEMPLATE_SMS[stage?.state as StateToSmsTemplateType];

        return {
            smsLinks: getEmployerTemplateLinks(smsTemplateType, isGuest ? ownerEmployerManagerId : undefined),
            mailLinks: getEmployerTemplateLinks(templateType, isGuest ? ownerEmployerManagerId : undefined),
        };
    }, [stage?.state, isGuest, ownerEmployerManagerId]);

    const smsForm = (
        <div
            className={classnames({
                [styles.hidden]: section !== 'sms',
            })}
        >
            <FinalField<boolean> name="sendSms" type="checkbox">
                {({ input, meta }) => (
                    <Cell
                        left={
                            <Checkbox
                                name={input.name}
                                invalid={meta.invalid}
                                onChange={input.onChange}
                                checked={sendMessage.value ? input.checked : false}
                            />
                        }
                        disabled={!sendMessage.value}
                    >
                        <CellText>{trls[TrlKeys.sendSms]}</CellText>
                    </Cell>
                )}
            </FinalField>
            <SmsTemplateField
                variables={filteredVariables}
                smsTemplates={smsTemplates}
                fallbackTemplate={stage?.templateInfo?.smsTemplate || ''}
                fallbackTemplateId={stage?.templateInfo?.smsEmployerManagerTemplateId || null}
                linkToEditTemplate={smsLinks.edit}
                linkToCreateTemplate={smsLinks.create}
                closeModal={closeModal}
            />
        </div>
    );

    const messageForm = !isTemplatesLoading ? (
        <div
            className={classnames({
                [styles.hidden]: section !== 'message',
            })}
        >
            <TemplateField
                variables={filteredVariables}
                isSubstate={isSubstate}
                stateName={stateName}
                mailTemplates={mailTemplates}
                fallbackTemplate={stage?.templateInfo?.template || ''}
                fallbackTemplateId={stage?.templateInfo?.employerManagerTemplateId || null}
                linkToEditTemplate={mailLinks.edit}
                linkToCreateTemplate={mailLinks.create}
                closeModal={closeModal}
            />
        </div>
    ) : (
        <Loader size={24} />
    );

    const addressForm = (
        <div className={classnames({ [styles.hidden]: section !== 'address' })}>
            <AddressField loading={isAddressLoading} />
        </div>
    );

    const content = (
        <>
            {isSubstate && <SubstateNameField stateName={stateName} modalType={modalType} />}
            {Object.keys(sections).length > 1 && (
                <>
                    <SegmentedControl active={section} sections={sections} onSelect={setSection} />
                    <VSpacing default={24} />
                </>
            )}
            {messageForm}
            {smsForm}
            {addressForm}
            <VSpacing default={24} />
            <Banner stretched showClose={false} style="secondary" content={trls[TrlKeys.banner]} />
            <VSpacing default={24} />
        </>
    );

    // Человечный способ подсветить ошибку внутри формы на segmented'е.
    // Т.к. при невалидном состоянии формы сабмит не сработает – завязываемся на click.
    const onBeforeSubmit = useCallback(() => {
        let newSection = section;
        const { errors, valid } = getState();
        if (!valid && Object.keys(errors).length) {
            const currentSectionFields = Object.keys(errors).filter((key) => FIELDS_TO_SECTION_MAP[key] === section);
            // Если мы на сегменте, где ошибка – не переключаемся
            if (currentSectionFields.length) {
                return;
            }
            const firstInvalidField = Object.keys(errors)
                .filter((key) => FIELDS_TO_SECTION_MAP[key] !== section)
                .find((key) => key in FIELDS_TO_SECTION_MAP);
            if (firstInvalidField) {
                newSection = FIELDS_TO_SECTION_MAP[firstInvalidField];
            }
        }

        if (newSection && newSection !== section) {
            setSection(newSection);
        }
    }, [getState, section]);

    const buttons = (
        <div className={classnames(styles.buttons, { [styles.column]: isMobile })}>
            <Button
                mode="primary"
                style="accent"
                stretched={isMobile}
                form={formId}
                onClick={onBeforeSubmit}
                type="submit"
                loading={submitting}
            >
                {trls[TrlKeys.save]}
            </Button>
        </div>
    );

    const oneClickCheckbox = stateName !== EmployerStateType.Assessment && (
        <FinalField<boolean> name="quickTransferToStageEnabled" type="checkbox">
            {({ input }) => (
                <Cell
                    align="top"
                    left={<Checkbox name={input.name} onChange={input.onChange} checked={input.checked} />}
                >
                    <CellText>{trls[TrlKeys.oneClickCheckbox[isSubstate ? 'substate' : 'state']]}</CellText>
                </Cell>
            )}
        </FinalField>
    );

    return (
        <>
            <Modal
                actions={<Action mode="secondary" onClick={closeModal} icon={CrossOutlinedSize24} />}
                title={trls[TrlKeys.header[modalType]]}
                titleSize="medium"
                titleDescription={
                    subtitle ? (
                        <Text style="secondary" typography="paragraph-2-regular">
                            {subtitle}
                        </Text>
                    ) : undefined
                }
                visible={!isMobile && isVisible}
                onClose={closeModal}
                footer={<ActionBar primaryActions={buttons} additionalContent={oneClickCheckbox} />}
            >
                {content}
            </Modal>
            <BottomSheet
                visible={isMobile && isVisible}
                onClose={closeModal}
                header={
                    <NavigationBar showDivider="always" title={trls[TrlKeys.header[modalType]]} subtitle={subtitle} />
                }
                footer={<BottomSheetFooter>{buttons}</BottomSheetFooter>}
            >
                {content}
                {oneClickCheckbox}
                <VSpacing default={12} />
            </BottomSheet>
        </>
    );
};

const Translated = translation(StageSettingsModal);

const Wrapped: FC<
    Omit<
        StageSettingsModalProps,
        | 'stateName'
        | 'invalid'
        | 'isAddressLoading'
        | 'mailTemplates'
        | 'smsTemplates'
        | 'isTemplatesLoading'
        | 'ownerEmployerManagerId'
    > & {
        onSubmit: Config<StageSettingsModalFormData>['onSubmit'];
        stage: VacancyFunnelStage | VacancyFunnelSubstate;
        formId: string;
    }
> = ({ onSubmit, stage, ...restProps }) => {
    const addressId = stage.templateInfo.addressId;
    const ownerEmployerManagerId = useSelectorNonNullable((state) => state.vacancyFunnel.ownerEmployerManagerId);
    const addresses = useSelectorNonNullable((state) => state.employerAddresses);
    const { loading } = useAddressLoading({
        addressId,
        employerId: ownerEmployerManagerId,
    });

    const newMailTemplatesEnabled = useFeatureEnabled(Features.newMailTemplatesEnabled);

    const [mailTemplates, setMailTemplates] = useState<ProcessedManagerTemplate[]>([]);
    const [smsTemplates, setSmsTemplates] = useState<ProcessedManagerTemplate[]>([]);
    const { addNotification } = useNotification();

    const abortFetchRef = useRef<AbortController | null>(null);
    const { loading: loadingTemplates, fetchRequest } = useFetchTemplatesByEmployerState({
        onSuccess: (templates) => {
            const { mailTemplates, smsTemplates } = processTemplates(
                templates.ownersTemplates,
                stage.state,
                stage.templateInfo.employerManagerTemplateId,
                stage.templateInfo.smsEmployerManagerTemplateId
            );
            setMailTemplates(mailTemplates);
            setSmsTemplates(smsTemplates);
        },
        onError: (error) => {
            defaultRequestErrorHandler(error, addNotification);
        },
        abortSignalRef: abortFetchRef,
    });

    useEffect(() => {
        const controller = abortFetchRef.current;
        if (
            !loadingTemplates &&
            stage.templateInfo.fromEmployerManagerTemplates &&
            newMailTemplatesEnabled &&
            !mailTemplates?.length &&
            !smsTemplates?.length
        ) {
            void fetchRequest({
                employerState: stage.state,
                employerManagerId: ownerEmployerManagerId,
                anonymousTemplates: false,
            });
        }
        return () => controller?.abort();
    }, [
        fetchRequest,
        ownerEmployerManagerId,
        loadingTemplates,
        newMailTemplatesEnabled,
        mailTemplates,
        smsTemplates,
        stage.state,
        stage.templateInfo.fromEmployerManagerTemplates,
    ]);

    const mailTemplateText = useMemo(
        () => (mailTemplates?.length ? getInitialTemplateText(mailTemplates) : stage.templateInfo.template),
        [mailTemplates, stage.templateInfo.template]
    );
    const smsTemplateText = useMemo(
        () => (smsTemplates?.length ? getInitialTemplateText(smsTemplates) : stage.templateInfo.smsTemplate),
        [smsTemplates, stage.templateInfo.smsTemplate]
    );

    const mailTemplateId = useMemo(
        () =>
            mailTemplates?.length ? getInitialTemplateId(mailTemplates) : stage.templateInfo.employerManagerTemplateId,
        [mailTemplates, stage.templateInfo.employerManagerTemplateId]
    );
    const smsTemplateId = useMemo(
        () =>
            smsTemplates?.length ? getInitialTemplateId(smsTemplates) : stage.templateInfo.smsEmployerManagerTemplateId,
        [smsTemplates, stage.templateInfo.smsEmployerManagerTemplateId]
    );

    return (
        <FinalForm<StageSettingsModalFormData>
            onSubmit={onSubmit}
            initialValues={{
                ...stage.templateInfo,
                quickTransferToStageEnabled: stage.quickTransferToStageEnabled,
                sendSms: stage.templateInfo.smsAllowed ? stage.templateInfo.sendSms : false,
                addressId: addresses.find((addr) => addr.id === stage.templateInfo.addressId)?.id,
                sendMessage: stage.substateName === null ? true : stage.templateInfo.sendMessage,
                substateName: stage.substateName ?? undefined,
                template: mailTemplateText,
                smsTemplate: smsTemplateText,
                employerManagerTemplateId: mailTemplateId,
                smsEmployerManagerTemplateId: smsTemplateId,
                ...(stage.templateInfo.addressAllowed && {
                    addressChecked: !!stage.templateInfo.addressId || stage.templateInfo.useAddressFromVacancy,
                }),
            }}
        >
            {({ handleSubmit, invalid }) => (
                <Form onSubmit={handleSubmit} id={restProps.formId}>
                    <Translated
                        {...restProps}
                        stateName={stage.state}
                        invalid={invalid}
                        isAddressLoading={loading}
                        mailTemplates={mailTemplates}
                        smsTemplates={smsTemplates}
                        isTemplatesLoading={loadingTemplates}
                        ownerEmployerManagerId={ownerEmployerManagerId.toString()}
                    />
                </Form>
            )}
        </FinalForm>
    );
};

export default Wrapped;
