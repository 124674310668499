import urlParser from 'bloko/common/urlParser';

import { TemplateType } from 'src/models/employerMailTemplates';

const BASE_URL = '/employer/settings/email';

export const getEmployerTemplateLinks = (type?: TemplateType | null, employerManagerId?: string) => {
    const url = urlParser(BASE_URL);

    const tab = type?.includes('ANONYMOUS') ? 'anonymous' : 'common';
    url.params.tab = tab;

    if (employerManagerId) {
        url.params.employerManagerId = employerManagerId;
    }

    return {
        edit: type ? `${url.href}&action=edit&type=${type}` : url.href,
        create: type ? `${url.href}&action=create&type=${type}` : url.href,
    };
};
