import { FC } from 'react';

import EmployerSubstateModal from 'src/components/VacancyFunnel/EmployerSubstateModal';
import AddSubstateButton from 'src/components/VacancyFunnel/Substate/AddSubstateButton';
import useOnOffState from 'src/hooks/useOnOffState';
import { VacancyFunnelStage } from 'src/models/vacancyFunnel';

interface AddSubstateTriggerProps {
    hideLabel?: boolean;
    stage: VacancyFunnelStage;
}

const AddSubstateTrigger: FC<AddSubstateTriggerProps> = ({ stage, hideLabel }) => {
    const [isModalVisible, setOn, setOff] = useOnOffState(false);
    return (
        <>
            <AddSubstateButton hideLabel={hideLabel} onClick={setOn} substatesCount={stage.substates?.length || 0} />
            {isModalVisible && (
                <EmployerSubstateModal visible={isModalVisible} setOff={setOff} stage={stage} mode="create" />
            )}
        </>
    );
};

export default AddSubstateTrigger;
