import { FC, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AxiosError } from 'axios';

import { useNotification } from 'src/components/Notifications/Provider';
import NotificationComponent from 'src/components/VacancyFunnel/Notifications';
import DeleteSubstateButton from 'src/components/VacancyFunnel/Substate/DeleteSubstateButton';
import { FunnelErrorCodes } from 'src/components/VacancyFunnel/types';
import { useSelectorNonNullable } from 'src/hooks/useSelector';
import { updateSubstates } from 'src/models/candidatesList';
import { deleteVacancyFunnelStage, VacancyFunnelStage, VacancyFunnelSubstate } from 'src/models/vacancyFunnel';
import fetcher from 'src/utils/fetcher';

import MoveTopicsModal from 'src/components/VacancyFunnel/StageList/Stage/DeleteSubstateTrigger/MoveTopicsModal';

interface DeleteSubstateTriggerProps {
    hideLabel?: boolean;
    stage: VacancyFunnelStage;
    substate: VacancyFunnelSubstate;
}

const DELETE_FUNNEL_STAGE_URL = '/shards/employer/vacancyresponses/funnel/delete';

declare global {
    interface FetcherDeleteApi {
        [DELETE_FUNNEL_STAGE_URL]: {
            queryParams: {
                funnelId: string;
                stageId: string;
                topicTransferStageId?: string;
            };
            body: void;
            response: void;
        };
    }
}

const DeleteSubstateTrigger: FC<DeleteSubstateTriggerProps> = ({ hideLabel, stage, substate }) => {
    const dispatch = useDispatch();
    const { addNotification } = useNotification();
    const funnelId = useSelectorNonNullable((state) => state.vacancyFunnel.funnelId);
    const [moveTopicsSubstate, setMoveTopicsSubstate] = useState<VacancyFunnelSubstate | null>(null);

    const onDeleteSubstate = useCallback(
        async (data: { stageId: number; substate: VacancyFunnelSubstate; topicTransferStageId?: string }) => {
            try {
                await fetcher.delete(DELETE_FUNNEL_STAGE_URL, {
                    params: {
                        funnelId: funnelId.toString(),
                        stageId: data.substate.funnelStageId.toString(),
                        topicTransferStageId: data.topicTransferStageId,
                    },
                });
                dispatch(
                    deleteVacancyFunnelStage({
                        funnelStageId: data.stageId.toString(),
                        funnelSubstateId: data.substate.funnelStageId.toString(),
                    })
                );
                dispatch(
                    updateSubstates({
                        eventType: 'delete',
                        name: substate.substateName,
                        id: `${substate.state.toLowerCase()}_${substate.funnelStageId}`,
                        parentId: substate.state.toLowerCase(),
                    })
                );
                addNotification(NotificationComponent, {
                    props: {
                        type: 'success',
                        status: data.substate.substateName,
                        actionType: 'deleteSubstate',
                    },
                });
            } catch (e) {
                const err = e as AxiosError<Partial<{ message: string; code: FunnelErrorCodes }> | void>;
                const errorCode = err.response?.data?.code;
                if (errorCode === FunnelErrorCodes.CannotDeleteFunnelStageWithTopics && !moveTopicsSubstate) {
                    setMoveTopicsSubstate(data.substate);
                    return;
                }
                const isFunnelLockedError = errorCode === FunnelErrorCodes.FunnelByIdLocked;
                addNotification(NotificationComponent, {
                    props: {
                        type: 'error',
                        status: data.substate.substateName,
                        actionType: isFunnelLockedError ? 'funnelLockedError' : 'defaultError',
                    },
                });
            }
        },
        [
            addNotification,
            dispatch,
            funnelId,
            moveTopicsSubstate,
            substate.funnelStageId,
            substate.state,
            substate.substateName,
        ]
    );

    return (
        <>
            <DeleteSubstateButton
                hideLabel={hideLabel}
                onClick={() => onDeleteSubstate({ stageId: stage.funnelStageId, substate })}
            />
            {moveTopicsSubstate && (
                <MoveTopicsModal
                    submit={onDeleteSubstate}
                    toggleVisibility={() => setMoveTopicsSubstate(null)}
                    substate={moveTopicsSubstate}
                    stage={stage}
                />
            )}
        </>
    );
};

export default DeleteSubstateTrigger;
