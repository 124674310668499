import { FocusEvent, useMemo } from 'react';
import { Field as FinalField } from 'react-final-form';

import { Input, VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import { StageSettingsModalState } from 'src/components/VacancyFunnel/types';
import translation from 'src/components/translation';
import { EmployerStateType } from 'src/models/negotiationTopic.types';
import { MAX_SUBASTATE_NAME_LENGTH, RECOMMENDED_SUBASTATE_NAME_LENGTH } from 'src/models/vacancyFunnel';

import SubstateNamesAdvice from 'src/components/VacancyFunnel/StageSettingsModal/SubstateNameField/SubstateNamesAdvice';

const FIELD_NAME = 'substateName';

const TrlKeys = {
    required: 'employer.vacancyresponses.funnel.stateSettingsModal.required',
    description: 'employer.vacancyresponses.funnel.stateSettingsModal.description',
};

const SubstateNameField: TranslatedComponent<{
    modalType: StageSettingsModalState;
    stateName: EmployerStateType;
}> = ({ trls, modalType, stateName }) => {
    const requiredValidation = useMemo(
        () => (input?: string) => (input?.trim() ? undefined : trls[TrlKeys.required]),
        [trls]
    );

    return (
        <FinalField<string> name={FIELD_NAME} validate={requiredValidation}>
            {({ input, meta }) => (
                <div
                    onBlur={(event: FocusEvent<HTMLElement>) => {
                        if (event.currentTarget.contains(event.relatedTarget)) {
                            event.preventDefault();
                            return;
                        }
                        input.onBlur(event);
                    }}
                >
                    <Input
                        maxLength={MAX_SUBASTATE_NAME_LENGTH}
                        description={format(trls[TrlKeys.description], { '{0}': RECOMMENDED_SUBASTATE_NAME_LENGTH })}
                        value={input.value || ''}
                        size="medium"
                        clearableOnFocus={false}
                        onChange={input.onChange}
                        onFocus={input.onFocus}
                        invalid={
                            meta.touched &&
                            meta.invalid &&
                            !meta.active &&
                            ((!!meta.submitError && !meta.dirtySinceLastSubmit) || !!meta.error)
                        }
                        errorMessage={(meta.error || meta.submitError) as string}
                    />
                    {modalType === StageSettingsModalState.SubstateCreating && (
                        <>
                            <VSpacing default={16} />
                            <SubstateNamesAdvice
                                stateName={stateName}
                                setSubstateName={(value) => input.onChange(value)}
                            />
                        </>
                    )}
                    <VSpacing default={24} />
                </div>
            )}
        </FinalField>
    );
};

export default translation(SubstateNameField);
