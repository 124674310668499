import { VacancyFunnel } from 'src/models/vacancyFunnel';

export const GET_FUNNEL_URL = '/shards/employer/vacancyresponses/funnel/get';

declare global {
    interface FetcherGetApi {
        [GET_FUNNEL_URL]: {
            queryParams: { funnelId: number };
            response: VacancyFunnel;
        };
    }
}
