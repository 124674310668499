import { CheckCircleFilledSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Notification from 'src/components/Notifications/Notification';
import { NotificationProps } from 'src/components/Notifications/Provider/types';
import translation from 'src/components/translation';
import { EmployerStateType } from 'src/models/negotiationTopic.types';

import getMessage, { Options } from 'src/components/VacancyFunnel/StageList/Stage/StageShowSuccessNotification/utils';

const TrlKeys = {
    state: {
        [EmployerStateType.Response]: 'negotiations.states.response',
        [EmployerStateType.Consider]: 'negotiations.states.consider',
        [EmployerStateType.PhoneInterview]: 'negotiations.states.phone_interview',
        [EmployerStateType.Assessment]: 'negotiations.states.assessment',
        [EmployerStateType.Interview]: 'negotiations.states.interview',
        [EmployerStateType.Invitation]: 'negotiations.states.invitation',
        [EmployerStateType.Offer]: 'negotiations.states.offer',
        [EmployerStateType.Hired]: 'negotiations.states.hired',
        [EmployerStateType.DiscardByApplicant]: 'negotiations.states.discardByApplicant',
        [EmployerStateType.DiscardByEmployer]: 'negotiations.states.discardByEmployer.softForm',
        [EmployerStateType.DiscardNoInteraction]: 'negotiations.states.discardNoInteraction',
        [EmployerStateType.DiscardToOtherVacancy]: 'negotiations.states.discardToOtherVacancy',
        [EmployerStateType.DiscardVacancyClosed]: 'negotiations.states.discardVacancyClosed',
    },
    message: {
        hasSubstates: 'employer.vacancyresponses.funnel.stage.showSuccessMessage.withSubstates',
        default: 'employer.vacancyresponses.funnel.stage.showSuccessMessage.default',
    },
};

type StageShowSuccessNotificationProps = NotificationProps & Omit<Options, 'trls' | 'TrlKeys'>;

const StageShowSuccessNotification: TranslatedComponent<StageShowSuccessNotificationProps> = ({
    trls,
    state,
    hasSubstates,
    ...notificationProps
}) => (
    <Notification addon={<CheckCircleFilledSize24 initialColor="positive" />} {...notificationProps}>
        {getMessage({ state, hasSubstates, trls, TrlKeys })}
    </Notification>
);

export default translation(StageShowSuccessNotification);
