import { FC, ReactNode, useRef } from 'react';

import { Divider } from '@hh.ru/magritte-ui';

import HiddenTag from 'src/components/VacancyFunnel/HiddenTag';
import StageCell from 'src/components/VacancyFunnel/StageCell';
import useDragAndDrop, { DragAndDropState } from 'src/components/VacancyFunnel/hooks/useDragAndDrop';
import { useSelector } from 'src/hooks/useSelector';

import styles from './styles.less';

interface StateProps {
    stateName: string;
    addSubstateTrigger?: ReactNode;
    updateStateTrigger?: ReactNode;
    toggleVisibilityTrigger?: ReactNode;
    hidden?: boolean;
    children: (dragAndDropProps: DragAndDropState) => ReactNode;
}

const State: FC<StateProps> = ({
    children,
    stateName,
    addSubstateTrigger,
    updateStateTrigger,
    toggleVisibilityTrigger,
    hidden,
}) => {
    const dropContainer = useRef<HTMLDivElement | null>(null);
    const dragAndDropProps = useDragAndDrop(dropContainer.current);
    const enabledHiding = useSelector((state) => state.enabledHidingForVacancyFunnelStages);

    return (
        <>
            <div className={styles.dividerWrapper}>
                <Divider />
            </div>
            <StageCell
                disabled={hidden}
                tags={hidden && <HiddenTag />}
                actions={
                    <>
                        {enabledHiding && toggleVisibilityTrigger}
                        {!hidden && addSubstateTrigger}
                        {!hidden && updateStateTrigger}
                    </>
                }
            >
                {stateName}
            </StageCell>
            <div ref={dropContainer}>{children(dragAndDropProps)}</div>
        </>
    );
};

export default State;
