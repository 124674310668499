import { DragEvent, DragEventHandler, useCallback, useState } from 'react';

type DragDirection = 'up' | 'down' | null;

export interface DragAndDropState {
    dragItemIndex: number | null;
    targetItemIndex: number | null;
    dragDirection: DragDirection;
    handleDragStart: (index: number) => void;
    handleDragEnd: () => void;
    handleDragEnter: (event: DragEvent<HTMLElement>, index: number) => void;
    handleDrop: (
        event: DragEvent<HTMLElement>,
        stageIndex: number,
        onDrop?: (targetItemIndex: number, dragItemIndex: number, stageIndex: number) => void
    ) => void;
    handleDragOver: DragEventHandler<HTMLElement>;
}

type UseDragAndDropHook = (dropContainer: HTMLElement | null) => DragAndDropState;

type GetDragDirectionFunction = (targetItemIndex: number | null, dragItemIndex: number | null) => DragDirection;

const getDragDirection: GetDragDirectionFunction = (targetItemIndex, dragItemIndex) => {
    if (targetItemIndex === dragItemIndex || targetItemIndex === null || dragItemIndex === null) {
        return null;
    }
    return targetItemIndex < dragItemIndex ? 'up' : 'down';
};

const useDragAndDrop: UseDragAndDropHook = (dropContainer) => {
    const [dragItemIndex, setDragItemIndex] = useState<number | null>(null);
    const [targetItemIndex, setTargetItemIndex] = useState<number | null>(null);
    const dragDirection = getDragDirection(targetItemIndex, dragItemIndex);

    const handleDragStart = useCallback<DragAndDropState['handleDragStart']>((index) => {
        setDragItemIndex(index);
    }, []);

    const handleDragEnd = useCallback<DragAndDropState['handleDragEnd']>(() => {
        setDragItemIndex(null);
        setTargetItemIndex(null);
    }, []);

    const handleDragEnter = useCallback<DragAndDropState['handleDragEnter']>(
        (event, index) => setTargetItemIndex(dropContainer?.contains(event.currentTarget) ? index : null),
        [dropContainer]
    );

    const handleDrop = useCallback<DragAndDropState['handleDrop']>(
        (event, stageIndex, onDrop) => {
            event.preventDefault();

            if (dragItemIndex === targetItemIndex || targetItemIndex === null || dragItemIndex === null) {
                return;
            }

            onDrop?.(targetItemIndex, dragItemIndex, stageIndex);
        },
        [dragItemIndex, targetItemIndex]
    );

    const handleDragOver = useCallback<DragEventHandler<HTMLElement>>((event) => {
        event.preventDefault();
    }, []);

    return {
        dragItemIndex,
        targetItemIndex,
        dragDirection,
        handleDragStart,
        handleDragEnd,
        handleDragEnter,
        handleDrop,
        handleDragOver,
    };
};

export default useDragAndDrop;
