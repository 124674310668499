import { useRef } from 'react';
import { Field as FinalField, useField } from 'react-final-form';

import { Card, Link, Text, TextArea } from '@hh.ru/magritte-ui';
import { SPALink } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Variables from 'src/components/Employer/TextareaWithVariables/Variables';
import { VSpacing } from 'src/components/MagritteRedesignComponents/Spacing';
import TemplatesSelect from 'src/components/VacancyFunnel/TemplatesSelect';
import translation from 'src/components/translation';
import useFeatureEnabled from 'src/hooks/useFeatureEnabled';
import { useSelectorNonNullable } from 'src/hooks/useSelector';
import { useTransliterateText } from 'src/hooks/useTransliterate';
import { VariableType, ProcessedManagerTemplate } from 'src/models/employerMailTemplates';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

const FIELD_NAME = 'smsTemplate';
const SMS_TEMPLATE_ID_FIELD = 'smsEmployerManagerTemplateId';

const SMS_MAX_LENGTH = 140;

interface SmsTemplateProps {
    variables: VariableType[];
    smsTemplates: ProcessedManagerTemplate[];
    fallbackTemplate: string;
    fallbackTemplateId: number | null;
    linkToEditTemplate: string;
    linkToCreateTemplate: string;
    closeModal: VoidFunction;
}

const TrlKeys = {
    smsPlaceholder: 'employer.vacancyresponses.funnel.stateSettingsModal.smsPlaceholder',
    smsHint: 'employer.vacancyresponses.funnel.stateSettingsModal.smsHint',
    error: 'employer.vacancyresponses.funnel.stateSettingsModal.smsLengthExceed',
    required: 'employer.vacancyresponses.funnel.stateSettingsModal.required',
    messageDesc1: 'employer.vacancyresponses.funnel.stateSettingsModal.messageDescription1',
    messageDesc2: 'employer.vacancyresponses.funnel.stateSettingsModal.messageDescription2',
    messageDesc3: 'employer.vacancyresponses.funnel.stateSettingsModal.messageDescription3',
    messageDesc4: 'employer.vacancyresponses.funnel.stateSettingsModal.messageDescription4',
    messageDesc5: 'employer.vacancyresponses.funnel.stateSettingsModal.messageDescription5',
};

const Features = {
    newMailTemplatesEnabled: 'new_mail_templates_for_funnel_enabled',
};

const SmsTemplateField: TranslatedComponent<SmsTemplateProps> = ({
    trls,
    variables,
    smsTemplates,
    fallbackTemplate,
    fallbackTemplateId,
    linkToEditTemplate,
    linkToCreateTemplate,
    closeModal,
}) => {
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    const { transliteration } = useSelectorNonNullable((state) => state.employerMailTemplates);
    const { input: sendSms } = useField<boolean>('sendSms', { subscription: { value: true } });
    const { input: sendMessage } = useField<boolean>('sendMessage', { subscription: { value: true } });
    const {
        input: { value },
    } = useField<string>(FIELD_NAME, { subscription: { value: true } });
    const { input: templateId } = useField<number | null>(SMS_TEMPLATE_ID_FIELD);
    const text = useTransliterateText(value, transliteration.mapping);
    const validation = (value: string) => {
        if (sendSms.value && !value) {
            return trls[TrlKeys.required];
        }
        return value && value.length > SMS_MAX_LENGTH ? trls[TrlKeys.error] : undefined;
    };

    const newMailTemplatesEnabled = useFeatureEnabled(Features.newMailTemplatesEnabled);

    if (!sendSms.value || !sendMessage.value) {
        return null;
    }

    const description = (
        <>
            <span>{trls[TrlKeys.messageDesc1]}</span>
            {NON_BREAKING_SPACE}
            <Link
                Element={SPALink}
                to={`${linkToEditTemplate}&id=${templateId.value}`}
                onClick={closeModal}
                target="_blank"
                inline
                typography="label-3-regular"
            >
                {trls[TrlKeys.messageDesc2]}
            </Link>
            {NON_BREAKING_SPACE}
            <span>{trls[TrlKeys.messageDesc3]}</span>
            {NON_BREAKING_SPACE}
            <Link
                Element={SPALink}
                to={linkToCreateTemplate}
                onClick={closeModal}
                target="_blank"
                inline
                typography="label-3-regular"
            >
                {trls[TrlKeys.messageDesc4]}
            </Link>
            {NON_BREAKING_SPACE}
            <span>{trls[TrlKeys.messageDesc5]}</span>
        </>
    );

    return (
        <FinalField<string> name={FIELD_NAME} validate={validation}>
            {({ input, meta }) => (
                <>
                    <VSpacing default={12} />

                    {smsTemplates.length > 1 && newMailTemplatesEnabled && (
                        <TemplatesSelect
                            templates={smsTemplates}
                            fallbackTemplate={fallbackTemplate}
                            fallbackTemplateId={fallbackTemplateId}
                            isSms
                        />
                    )}

                    {text && (
                        <>
                            <Card padding={16} borderRadius={16} style="secondary" stretched>
                                <Text typography="paragraph-2-regular">{text}</Text>
                            </Card>
                            <VSpacing default={12} />
                        </>
                    )}

                    <TextArea
                        elevatePlaceholder
                        ref={textareaRef}
                        onBlur={input.onBlur}
                        placeholder={trls[TrlKeys.smsPlaceholder]}
                        onChange={input.onChange}
                        value={input.value}
                        layout="hug"
                        description={trls[TrlKeys.smsHint]}
                        invalid={meta.invalid}
                        errorMessage={meta.error as string}
                        disabled={!!smsTemplates.length && newMailTemplatesEnabled}
                    />
                    {!!smsTemplates.length && newMailTemplatesEnabled ? (
                        <>
                            <VSpacing default={8} />
                            <Text typography="label-3-regular" style="secondary">
                                {description}
                            </Text>
                        </>
                    ) : (
                        <Variables variables={variables} fieldName={FIELD_NAME} textareRef={textareaRef} />
                    )}
                </>
            )}
        </FinalField>
    );
};

export default translation(SmsTemplateField);
