import { Dispatch, RefObject, SetStateAction, useEffect, useRef } from 'react';

export const useInsertValueToTextarea = (
    textareaRef: RefObject<HTMLTextAreaElement>,
    textareaValue: string,
    setTextareaValue: Dispatch<SetStateAction<string>>
): ((text: string) => void) => {
    const caretPosition = useRef<number>();
    const scrollPosition = useRef<number>();
    const tag = useRef<string>();
    const tagInserted = useRef<boolean>(false);
    const insertValue = (text: string) => {
        tag.current = `[${text}] `;
        if (textareaRef.current) {
            caretPosition.current = textareaRef.current.selectionStart;
            scrollPosition.current = textareaRef.current.scrollTop;
        }
        if (caretPosition.current !== undefined && textareaRef.current) {
            tagInserted.current = true;
            setTextareaValue(
                textareaValue.slice(0, caretPosition.current) + tag.current + textareaValue.slice(caretPosition.current)
            );
        }
    };

    useEffect(() => {
        if (
            textareaRef.current &&
            caretPosition.current !== undefined &&
            tag.current &&
            tagInserted.current &&
            scrollPosition.current !== undefined
        ) {
            textareaRef.current.focus();
            textareaRef.current.selectionEnd = caretPosition.current + tag.current.length;
            textareaRef.current.scrollTop = scrollPosition.current;
            tagInserted.current = false;
        }
    }, [textareaRef, textareaValue]);

    return insertValue;
};
