import { Tag } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

const TrlKeys = {
    text: 'employer.vacancyresponses.funnel.tag.hidden',
};

const HiddenTag: TranslatedComponent = ({ trls }) => <Tag>{trls[TrlKeys.text]}</Tag>;

export default translation(HiddenTag);
