import { useCallback, useEffect, useRef, useState } from 'react';
import { Field, useField } from 'react-final-form';
import { useDispatch } from 'react-redux';

import { Cell, CellText, Link, Switch, VSpacing } from '@hh.ru/magritte-ui';
import { PlusOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer/lib/createReducer';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import {
    getCurrentAddress,
    isNonEmptyAddress,
    prepareAddress,
    PreparedAddress,
} from 'Modules/EmployerAddresses/helpers';
import AddressSuggest from 'src/components/AddressSuggest';
import AddAddressModal, { AddAddressModalProps } from 'src/components/AddressSuggest/AddAddressModal';
import Loading from 'src/components/ChangeTopicForm/Loading';
import translation from 'src/components/translation';
import useOnOffState from 'src/hooks/useOnOffState';
import { useSelector } from 'src/hooks/useSelector';
import { EmployerAddress } from 'src/models/employerAddresses.types';
import { StageSettingsModalFormData } from 'src/models/vacancyFunnel';

const ADDRESS_ID_FIELD = 'addressId';
const ADDRESS_CHECKED_FIELD = 'addressChecked';
const ADDRESS_FROM_VACANCY_FIELD = 'useAddressFromVacancy';
const ADDRESS_SUGGEST_LIMIT = 20;
const MAGRITTE_PADDING = 16;

const TrlKeys = {
    metro: 'metrostation',
    inviteToOffice: 'employer.vacancyresponses.funnel.stateSettingsModal.inviteToOffice',
    addressFromVacancy: 'employer.vacancyresponses.funnel.stateSettingsModal.addressFromVacancy',
    addAddress: 'employer.invite.enterAddress',
    address: 'employer.vacancyresponses.funnel.stateSettingsModal.address',
    required: 'employer.vacancyresponses.funnel.stateSettingsModal.required',
};

const employerAddressesCountActions = makeSetStoreField('employerAddressesCount');
const employerAddressesActions = makeSetStoreField('employerAddresses');

interface AddressFieldProps {
    loading: boolean;
}

const AddressField: TranslatedComponent<AddressFieldProps> = ({ loading, trls }) => {
    const dispatch = useDispatch();
    const addresses = useSelector((state) => state.employerAddresses);
    const employerAddressesCount = useSelector((state) => state.employerAddressesCount);
    const remoteSearch = useSelector((state) => state.addressesSuggestRemoteMode);

    const addressField = useField<number | undefined>(ADDRESS_ID_FIELD, { subscription: { value: true } });
    const addressId = addressField.input.value;
    const changeAddressId = addressField.input.onChange;

    const [selectedAddress, setSelectedAddress] = useState(
        getCurrentAddress(addresses, addressId, trls[TrlKeys.metro])
    );

    const [query, setQuery] = useState(selectedAddress?.text || '');
    const [addAddressModalVisible, showAddAddressModalVisible, hideAddAddressModalVisible] = useOnOffState(false);

    const {
        input: { value: isChecked, onChange: onChangeCheckbox },
    } = useField<boolean>(ADDRESS_CHECKED_FIELD, { subscription: { value: true } });

    const {
        input: { value: isAddressFromVacancy, onChange: onChangeAddressFromVacancyCheckbox },
    } = useField<boolean>(ADDRESS_FROM_VACANCY_FIELD);

    const [isAddressSelectVisible, setAddressSelectVisible] = useState(
        isNonEmptyAddress(selectedAddress) && !isAddressFromVacancy
    );

    const addAddressToStore = useCallback(
        (address: PreparedAddress) => {
            if (isNonEmptyAddress(address) && !addresses.some((item) => item.id === address.source.id)) {
                dispatch(employerAddressesActions([...addresses, address.source as EmployerAddress]));
            }
        },
        [addresses, dispatch]
    );

    const handleInputChange = (value: string) => {
        setQuery(value);
    };

    const onSelect = useCallback(
        (address: PreparedAddress) => {
            addAddressToStore(address);

            changeAddressId('id' in address ? address.id : null);
            setSelectedAddress(address);
            setQuery(address.text);
        },
        [addAddressToStore, changeAddressId]
    );

    const handleSelect = useCallback<AddAddressModalProps['onSelect']>(
        (rawAddress) => {
            const address = prepareAddress(rawAddress, trls[TrlKeys.metro]);
            onSelect(address);
        },
        [onSelect, trls]
    );

    const handleCreate = useCallback<AddAddressModalProps['onCreate']>(
        (rawAddress) => {
            dispatch(employerAddressesCountActions(employerAddressesCount ? employerAddressesCount + 1 : 1));
            const address = prepareAddress(rawAddress, trls[TrlKeys.metro]);
            onSelect(address);
            hideAddAddressModalVisible();
        },
        [dispatch, employerAddressesCount, onSelect, trls, hideAddAddressModalVisible]
    );

    const validation = useCallback(
        (value: string, allValues: object) => {
            const values = allValues as StageSettingsModalFormData;
            if (!values.addressChecked || (values.addressChecked && values.useAddressFromVacancy)) {
                return undefined;
            }
            return !value ? trls[TrlKeys.required] : undefined;
        },
        [trls]
    );

    const handleAddressCheckboxSwitcher = (isChecked: boolean) => {
        onChangeAddressFromVacancyCheckbox(isChecked);
    };

    const handleAddressFromVacancySwitcher = (isAddressFromVacancy: boolean) => {
        if (isAddressFromVacancy && !isChecked) {
            onChangeCheckbox(isAddressFromVacancy);
        }
    };

    useEffect(() => {
        if (isChecked && !isAddressFromVacancy) {
            setAddressSelectVisible(true);
            return;
        }
        setAddressSelectVisible(false);
    }, [isAddressFromVacancy, isChecked]);

    const [maxHeight, setMaxHeight] = useState(460);
    const selectRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const calculateMaxHeight = () => {
            if (selectRef.current && typeof window !== 'undefined') {
                return window.innerHeight - selectRef.current.getBoundingClientRect().bottom - 2 * MAGRITTE_PADDING;
            }

            return 460;
        };
        setMaxHeight(calculateMaxHeight());
    }, [isChecked]);

    useEffect(() => {
        if (selectedAddress?.id !== addressId) {
            const preparedAddress = getCurrentAddress(addresses, addressId, trls[TrlKeys.metro]);
            setSelectedAddress(preparedAddress);
            setQuery(preparedAddress.text);
        }
    }, [addressId, addresses, trls, selectedAddress.id]);

    return (
        <>
            {/* Фейковый чекбокс, который нужен чтобы триггерить дефолтную валидацию
             * поля ADDRESS_ID_FIELD. А также для обработки значения в сабмитах */}
            <Field<boolean> name={ADDRESS_CHECKED_FIELD} type="checkbox">
                {({ input }) => (
                    <Cell
                        vertPadding
                        right={
                            <Switch
                                checked={input.checked}
                                onClick={() => {
                                    const newValue = !input.checked;
                                    input.onChange(newValue);
                                    handleAddressCheckboxSwitcher(newValue);
                                }}
                            />
                        }
                    >
                        <CellText>{trls[TrlKeys.inviteToOffice]}</CellText>
                    </Cell>
                )}
            </Field>
            <Field<boolean> name={ADDRESS_FROM_VACANCY_FIELD} type="checkbox">
                {({ input }) => (
                    <Cell
                        vertPadding
                        right={
                            <Switch
                                checked={input.checked}
                                onClick={() => {
                                    const newValue = !input.checked;
                                    input.onChange(newValue);
                                    handleAddressFromVacancySwitcher(newValue);
                                }}
                            />
                        }
                    >
                        <CellText>{trls[TrlKeys.addressFromVacancy]}</CellText>
                    </Cell>
                )}
            </Field>
            {loading ? (
                <Loading />
            ) : (
                <Field<string> name={ADDRESS_ID_FIELD} validate={validation}>
                    {({ input, meta }) => (
                        <>
                            {isAddressSelectVisible && (
                                <>
                                    {!!employerAddressesCount && (
                                        <>
                                            <VSpacing default={16} />
                                            <div ref={selectRef}>
                                                <AddressSuggest
                                                    addresses={addresses}
                                                    remoteSearch={remoteSearch}
                                                    address={selectedAddress}
                                                    inputValue={query}
                                                    onSelect={onSelect}
                                                    invalid={!!meta.error && meta.touched}
                                                    limit={ADDRESS_SUGGEST_LIMIT}
                                                    placeholder={trls[TrlKeys.address]}
                                                    maxHeight={maxHeight}
                                                    inputProps={{
                                                        value: input.value,
                                                        name: input.name,
                                                        onChange: handleInputChange,
                                                        elevatePlaceholder: true,
                                                        errorMessage: trls[TrlKeys.required],
                                                    }}
                                                    trls={trls}
                                                    navigationTitle={trls[TrlKeys.address]}
                                                />
                                            </div>
                                        </>
                                    )}
                                    <VSpacing default={16} />
                                    <Link
                                        iconLeft={<PlusOutlinedSize16 />}
                                        style="accent"
                                        typography="label-2-regular"
                                        onClick={showAddAddressModalVisible}
                                    >
                                        {trls[TrlKeys.addAddress]}
                                    </Link>
                                </>
                            )}
                        </>
                    )}
                </Field>
            )}

            <AddAddressModal
                visible={addAddressModalVisible}
                onClose={hideAddAddressModalVisible}
                onCreate={handleCreate}
                onSelect={handleSelect}
            />
        </>
    );
};

export default translation(AddressField);
