import { Ref, useEffect, useMemo, useState } from 'react';
import { useField, Field } from 'react-final-form';
import { useDispatch } from 'react-redux';

import {
    Select,
    VSpacing,
    findStaticDataFetcherItemByValue,
    StaticDataFetcherItem,
    useStaticDataProvider,
} from '@hh.ru/magritte-ui';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { MarkAsViewedEvent } from 'Modules/Notices';
import MagritteInfotip from 'src/components/Infotip/MagritteInfotip';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { ProcessedManagerTemplate } from 'src/models/employerMailTemplates';

const INFOTIP_NAME = 'templates-select-infotip-funnel';
const MAIL_TEMPLATE_ID_FIELD = 'employerManagerTemplateId';
const SMS_TEMPLATE_ID_FIELD = 'smsEmployerManagerTemplateId';

const TrlKeys = {
    tooltipContent: 'employer.vacancyresponses.funnel.stateSettingsModal.select.tooltip',
    defaultLabel: 'employer.vacancyresponses.funnel.stateSettingsModal.select.defaultLabel',
};

interface TemplatesSelectProps {
    templates: ProcessedManagerTemplate[];
    fallbackTemplate: string;
    fallbackTemplateId: number | null;
    isSms?: boolean;
}

const setTemplatesSelectFunnelInfotipVisibleAction = makeSetStoreField('isTemplatesSelectFunnelInfotipVisible');

const TemplatesSelect: TranslatedComponent<TemplatesSelectProps> = ({
    templates,
    fallbackTemplate,
    fallbackTemplateId,
    isSms = false,
    trls,
}) => {
    const isVisible = useSelector((state) => state.isTemplatesSelectFunnelInfotipVisible);
    const template = useField(isSms ? 'smsTemplate' : 'template').input;
    const [selectedTemplate, setSelectedTemplate] = useState(templates.find((temp) => temp?.isSelected)?.id);
    const { input: templateId } = useField<number | null>(isSms ? SMS_TEMPLATE_ID_FIELD : MAIL_TEMPLATE_ID_FIELD);
    const dispatch = useDispatch();

    const items: StaticDataFetcherItem[] = useMemo(
        () =>
            templates.map((template) => ({
                text: template?.templateName,
                value: template?.templateName,
            })),
        [templates]
    );

    const provider = useStaticDataProvider(items, trls[TrlKeys.defaultLabel]);

    const currentTemplate =
        templates.find((temp) => temp?.id === selectedTemplate) ||
        templates.find((temp) => temp?.primaryTemplate) ||
        templates[0];

    const onChangeSelect = (newItem: StaticDataFetcherItem) => {
        const updatedTemplates = templates.map((temp) => ({
            ...temp,
            isSelected: temp.templateName === newItem.value,
        }));

        const newTemplate = updatedTemplates.find((temp) => temp.isSelected);
        setSelectedTemplate(newTemplate ? newTemplate.id : updatedTemplates[0].id);
        template.onChange(newTemplate ? newTemplate.template : fallbackTemplate);
        templateId.onChange(newTemplate ? newTemplate.id : fallbackTemplateId);
    };

    useEffect(() => {
        return () => {
            dispatch(setTemplatesSelectFunnelInfotipVisibleAction(false));
        };
    }, [dispatch]);

    return (
        <>
            <MagritteInfotip
                name={INFOTIP_NAME}
                placement="bottom-right"
                showClose
                visible={isVisible}
                onClose={() => {
                    dispatch(setTemplatesSelectFunnelInfotipVisibleAction(false));
                }}
                markAsViewedEvent={MarkAsViewedEvent.OnHide}
                autoHide
                render={({ activatorRef }) => (
                    <>
                        <div ref={activatorRef as Ref<HTMLDivElement>}>
                            <Select
                                type="radio"
                                value={findStaticDataFetcherItemByValue(currentTemplate.templateName, items)}
                                name="template"
                                dataProvider={provider}
                                triggerProps={{
                                    size: 'medium',
                                    stretched: true,
                                }}
                                onChange={onChangeSelect}
                            />
                        </div>
                        <VSpacing default={16} />
                    </>
                )}
            >
                <div>{trls[TrlKeys.tooltipContent]}</div>
            </MagritteInfotip>
            <Field<number | null>
                type="hidden"
                name={isSms ? SMS_TEMPLATE_ID_FIELD : MAIL_TEMPLATE_ID_FIELD}
                component="input"
            />
        </>
    );
};

export default translation(TemplatesSelect);
