import { EmployerStateType } from 'src/models/negotiationTopic.types';

const HIDABLE_STATE = new Map([
    [EmployerStateType.Consider, true],
    [EmployerStateType.Assessment, true],
]);

const isHidableState = (state: EmployerStateType): boolean => !!HIDABLE_STATE.get(state);

export default isHidableState;
