import { ChipsContainer, CustomChip } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelectorNonNullable } from 'src/hooks/useSelector';
import { EmployerStateType } from 'src/models/negotiationTopic.types';

const TrlKeys = {
    CONSIDER: {
        consider1: 'employer.funnel.substate.suggest.consider1',
        consider2: 'employer.funnel.substate.suggest.consider2',
    },
    PHONE_INTERVIEW: {
        phoneInterview1: 'employer.funnel.substate.suggest.phone_interview1',
        phoneInterview2: 'employer.funnel.substate.suggest.phone_interview2',
        phoneInterview3: 'employer.funnel.substate.suggest.phone_interview3',
    },
    ASSESSMENT: {
        assessment1: 'employer.funnel.substate.suggest.assessment1',
        assessment2: 'employer.funnel.substate.suggest.assessment2',
        assessment3: 'employer.funnel.substate.suggest.assessment3',
    },
    INTERVIEW: {
        interview1: 'employer.funnel.substate.suggest.interview1',
        interview2: 'employer.funnel.substate.suggest.interview2',
    },
    OFFER: {
        offer1: 'employer.funnel.substate.suggest.offer1',
        offer2: 'employer.funnel.substate.suggest.offer2',
        offer3: 'employer.funnel.substate.suggest.offer3',
    },
    HIRED: {
        hired1: 'employer.funnel.substate.suggest.hired1',
        hired2: 'employer.funnel.substate.suggest.hired2',
    },
    DISCARD_BY_EMPLOYER: {
        discardByEmployer1: 'employer.funnel.substate.suggest.discard_by_employer1',
        discardByEmployer2: 'employer.funnel.substate.suggest.discard_by_employer2',
        discardByEmployer3: 'employer.funnel.substate.suggest.discard_by_employer3',
        discardByEmployer4: 'employer.funnel.substate.suggest.discard_by_employer4',
        discardByEmployer5: 'employer.funnel.substate.suggest.discard_by_employer5',
    },
    DISCARD_BY_APPLICANT: {
        discardByApplicant1: 'employer.funnel.substate.suggest.discard_by_applicant1',
        discardByApplicant2: 'employer.funnel.substate.suggest.discard_by_applicant2',
        discardByApplicant3: 'employer.funnel.substate.suggest.discard_by_applicant3',
    },
    DISCARD_NO_INTERACTION: {
        discardNoInteraction1: 'employer.funnel.substate.suggest.discard_no_interaction1',
        discardNoInteraction2: 'employer.funnel.substate.suggest.discard_no_interaction2',
    },
};

const SubstateNamesAdvice: TranslatedComponent<{
    setSubstateName: (val: string) => void;
    stateName: EmployerStateType;
}> = ({ trls, setSubstateName, stateName }) => {
    const existingSubstates = useSelectorNonNullable(
        (state) => state.vacancyFunnel.stages?.find((stage) => stage.state === stateName)?.substates || []
    );
    const suggests = stateName in TrlKeys ? TrlKeys[stateName as keyof typeof TrlKeys] : null;

    if (!suggests) {
        return null;
    }

    const filteredSuggests = Object.values(suggests).filter(
        (name) => !existingSubstates.find((substate) => substate.substateName === trls[name].replaceAll(/\s/g, ' '))
    );

    if (!filteredSuggests.length) {
        return null;
    }

    return (
        <ChipsContainer>
            {Object.values(filteredSuggests).map((name) => (
                <CustomChip Element="button" onClick={() => setSubstateName(trls[name])} key={name}>
                    {trls[name]}
                </CustomChip>
            ))}
        </ChipsContainer>
    );
};

export default translation(SubstateNamesAdvice);
