import { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { AnyAction } from 'redux';

import { defaultMagritteRequestErrorHandler } from 'src/api/notifications/defaultRequestErrorHandler';
import { useNotification } from 'src/components/Notifications/Provider';
import StageHideSuccessNotification from 'src/components/VacancyFunnel/StageList/Stage/StageHideSuccessNotification';
import StageShowSuccessNotification from 'src/components/VacancyFunnel/StageList/Stage/StageShowSuccessNotification';
import StageVisibilityAlert from 'src/components/VacancyFunnel/StageList/Stage/StageVisibilityAlert';
import StageVisibilityButton from 'src/components/VacancyFunnel/State/StageVisibilityButton';
import useUpdateFunnelStage from 'src/components/VacancyFunnel/api/useUpdateFunnelStage';
import useHasResponses from 'src/components/VacancyResponses/hooks/useHasResponses';
import getCollectionNameByEmployerState from 'src/components/VacancyResponses/utils/getCollectionNameByEmployerState';
import useHasAutomationTools from 'src/components/VacancyResponsesAutomation/hooks/useHasAutomationTools';
import useOnOffState from 'src/hooks/useOnOffState';
import { useSelector } from 'src/hooks/useSelector';
import { setCollectionVisibility } from 'src/models/candidatesList';
import { updateVacancyFunnelStage, VacancyFunnelStage } from 'src/models/vacancyFunnel';

interface StateVisibilityTriggerProps {
    hideLabel?: boolean;
    stage: VacancyFunnelStage;
}

const StageVisibilityTrigger: FC<StateVisibilityTriggerProps> = ({ stage, hideLabel }) => {
    const dispatch = useDispatch();
    const { addNotification } = useNotification();
    const [isVisibleAlert, showAlert, hideAlert] = useOnOffState(false);
    const { funnelStageId, quickTransferToStageEnabled, templateInfo, substateName, hidden } = stage;
    const { hasAutomationTools } = useHasAutomationTools();
    const hasVacancyContext = useSelector((state) => !!state.router.location.query.vacancyId);
    const funnelId = useSelector((state) => state.vacancyFunnel?.funnelId);
    const collectionName = getCollectionNameByEmployerState(stage.state);
    const hasResponses = useHasResponses(collectionName);
    const hasSubstates = !!stage.substates?.length;

    const { loading, fetchRequest, abortController } = useUpdateFunnelStage({
        onSuccess: (stage: VacancyFunnelStage) => {
            hideAlert();

            const actions: AnyAction[] = [updateVacancyFunnelStage(stage)];

            if (collectionName) {
                actions.push(setCollectionVisibility({ collectionName, hidden: stage.hidden }));
            }

            dispatch(actions);

            if (stage.hidden) {
                addNotification(StageHideSuccessNotification, {
                    props: { state: stage.state, hasSubstates, hasResponses },
                });
            } else {
                addNotification(StageShowSuccessNotification, { props: { state: stage.state, hasSubstates } });
            }
        },
        onError: (error) => {
            hideAlert();
            defaultMagritteRequestErrorHandler(error, addNotification);
        },
    });

    const toggleStageVisibility = useCallback(() => {
        if (!funnelId) {
            return;
        }

        if (hasResponses && !isVisibleAlert && !hidden) {
            showAlert();
            return;
        }

        void fetchRequest({
            funnelId: funnelId.toString(),
            stageId: funnelStageId.toString(),
            substateName,
            templateInfo,
            quickTransferToStageEnabled,
            hidden: !hidden,
        });
    }, [
        fetchRequest,
        funnelId,
        funnelStageId,
        hasResponses,
        hidden,
        isVisibleAlert,
        quickTransferToStageEnabled,
        showAlert,
        substateName,
        templateInfo,
    ]);

    const handleAlertCancelButtonClick = useCallback(() => {
        abortController?.abort();
        hideAlert();
    }, [abortController, hideAlert]);

    return (
        <>
            <StageVisibilityButton
                hidden={hidden}
                onClick={toggleStageVisibility}
                hideLabel={hideLabel}
                loading={loading}
            />
            <StageVisibilityAlert
                visible={isVisibleAlert}
                loading={loading}
                hasVacancyContext={hasVacancyContext}
                hasSubstates={hasSubstates}
                hasAutomationTools={hasAutomationTools}
                onConfirm={toggleStageVisibility}
                onCancel={handleAlertCancelButtonClick}
            />
        </>
    );
};

export default StageVisibilityTrigger;
