import { MutableRefObject } from 'react';

import useFetch, { UseFetchReturn } from 'src/hooks/useFetch';
import { Template } from 'src/models/employerMailTemplates';
import { EmployerStateType } from 'src/models/negotiationTopic.types';
import fetcher from 'src/utils/fetcher';

const FETCH_GET_MAIL_TEMPLATES = '/shards/employer/mail_templates/get_templates_by_employer_state';

interface FetchMailTemplatesQuery {
    employerManagerId?: number;
    employerState: EmployerStateType;
    anonymousTemplates?: boolean;
}

declare global {
    interface FetcherGetApi {
        [FETCH_GET_MAIL_TEMPLATES]: {
            queryParams: FetchMailTemplatesQuery;
            response: { ownersTemplates: Template[]; currentEmployerTemplates: Template[] };
        };
    }
}

type UseFetchTemplatesByEmployerStateHook = (props: {
    onSuccess: (data: { ownersTemplates: Template[]; currentEmployerTemplates: Template[] }) => void;
    onError: (error: unknown) => void;
    abortSignalRef: MutableRefObject<AbortController | null>;
}) => UseFetchReturn<FetchMailTemplatesQuery, { ownersTemplates: Template[]; currentEmployerTemplates: Template[] }>;

const useFetchTemplatesByEmployerState: UseFetchTemplatesByEmployerStateHook = ({
    onSuccess,
    onError,
    abortSignalRef,
}) => {
    return useFetch<FetchMailTemplatesQuery, { ownersTemplates: Template[]; currentEmployerTemplates: Template[] }>(
        async ({ employerState, employerManagerId, anonymousTemplates }) => {
            abortSignalRef.current?.abort();
            abortSignalRef.current = new AbortController();

            const { ownersTemplates, currentEmployerTemplates } = await fetcher.get(FETCH_GET_MAIL_TEMPLATES, {
                params: { employerState, employerManagerId, anonymousTemplates },
                signal: abortSignalRef.current.signal,
            });

            return { ownersTemplates, currentEmployerTemplates };
        },
        {
            onSuccess,
            onError,
        }
    );
};

export default useFetchTemplatesByEmployerState;
