import { LangTrls } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import { EmployerStateType } from 'src/models/negotiationTopic.types';

type ConditionKey = 'default' | 'hasSubstates';

interface TrlKeys {
    state: Record<EmployerStateType, string>;
    message: Record<ConditionKey, string>;
}

export interface Options {
    state: EmployerStateType;
    hasSubstates?: boolean;
    trls: LangTrls;
    TrlKeys: TrlKeys;
}

const getMessageTrlKey = ({ hasSubstates, TrlKeys }: Omit<Options, 'state' | 'trls'>): string => {
    if (hasSubstates) {
        return TrlKeys.message.hasSubstates;
    }
    return TrlKeys.message.default;
};

const getMessage = ({ state, hasSubstates, trls, TrlKeys }: Options): string => {
    return format(trls[getMessageTrlKey({ hasSubstates, TrlKeys })], { '{0}': trls[TrlKeys.state[state]] });
};

export default getMessage;
