import { Button } from '@hh.ru/magritte-ui';
import { EyeCrossedOutlinedSize16, EyeOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

const TrlKeys = {
    hideLabel: 'employer.vacancyresponses.funnel.hide',
    showLabel: 'employer.vacancyresponses.funnel.show',
};

interface StageVisibilityButtonProps {
    hidden: boolean;
    onClick: VoidFunction;
    hideLabel?: boolean;
    loading?: boolean;
}

const StageVisibilityButton: TranslatedComponent<StageVisibilityButtonProps> = ({
    trls,
    hidden,
    onClick,
    hideLabel,
    loading,
}) => (
    <Button
        size="small"
        style="neutral"
        mode="tertiary"
        icon={hidden ? <EyeOutlinedSize16 /> : <EyeCrossedOutlinedSize16 />}
        onClick={onClick}
        hideLabel={hideLabel}
        loading={loading}
    >
        {trls[hidden ? TrlKeys.showLabel : TrlKeys.hideLabel]}
    </Button>
);

export default translation(StageVisibilityButton);
