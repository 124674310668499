import { useRef } from 'react';

import useFetch, { UseFetchOptions, UseFetchReturn } from 'src/hooks/useFetch';
import { TemplateInfo, VacancyFunnelStage } from 'src/models/vacancyFunnel';
import fetcher from 'src/utils/fetcher';

interface Payload {
    funnelId: string;
    quickTransferToStageEnabled: boolean;
    stageId: string;
    substateName: string | null;
    templateInfo: TemplateInfo;
    hidden?: boolean;
}

const URL = '/shards/employer/vacancyresponses/funnel/update';

declare global {
    interface FetcherPutApi {
        [URL]: {
            queryParams: void;
            body: Payload;
            response: VacancyFunnelStage;
        };
    }
}

type UseUpdateFunnelStageHook = (
    options: UseFetchOptions<Payload, VacancyFunnelStage>
) => UseFetchReturn<Payload, VacancyFunnelStage> & { abortController: AbortController | null };

const useUpdateFunnelStage: UseUpdateFunnelStageHook = ({ onSuccess, onError }) => {
    const abortController = useRef<AbortController | null>(null);
    return {
        ...useFetch(
            async (payload) => {
                abortController.current?.abort();
                abortController.current = new AbortController();
                const { data } = await fetcher.put(URL, payload, { signal: abortController.current.signal });
                return data;
            },
            { onSuccess, onError }
        ),
        abortController: abortController.current,
    };
};

export default useUpdateFunnelStage;
