import { useCallback, useMemo, useState } from 'react';

import {
    Action,
    BottomSheet,
    BottomSheetFooter,
    Button,
    Card,
    Modal,
    NavigationBar,
    Text,
    useBreakpoint,
    ActionBar,
    createDataProvider,
    createStaticDataFetcher,
    DataProvider,
    DataSection,
    DataSectionOfType,
    decorateWithResultProducer,
    StaticDataFetcherItem,
} from '@hh.ru/magritte-ui';
import { Select } from '@hh.ru/magritte-ui-select';
import { ArrowRightOutlinedSize24, CrossOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import SelectFixedHeight from 'src/components/VacancyFunnel/SelectFixedHeight';
import { EmployerStateTrlKeys } from 'src/components/VacancyFunnel/trls';
import translation from 'src/components/translation';
import { useSelectorNonNullable } from 'src/hooks/useSelector';
import { VacancyFunnelStage, VacancyFunnelSubstate } from 'src/models/vacancyFunnel';

import styles from './index.less';

interface MoveTopicsModalProps {
    toggleVisibility: () => void;
    stage: VacancyFunnelStage;
    substate: VacancyFunnelSubstate;
    submit: (data: { stageId: number; substate: VacancyFunnelSubstate; topicTransferStageId?: string }) => void;
}

const TrlKeys = {
    title: 'employer.vacancyresponses.funnel.moveTopicsModal.title',
    subtitle: 'employer.vacancyresponses.funnel.moveTopicsModal.subtitle',
    submit: 'employer.vacancyresponses.funnel.moveTopicsModal.submit',
    state: 'employer.vacancyresponses.funnel.moveTopicsModal.state',
    substate: 'employer.vacancyresponses.funnel.moveTopicsModal.substate',
    newStatePlaceholder: 'employer.vacancyresponses.funnel.moveTopicsModal.newStatePlaceholder',
};

interface VacancyFunnelStageItem extends StaticDataFetcherItem {
    text: string;
    stage?: VacancyFunnelStage;
    substate?: VacancyFunnelSubstate;
}

const MoveTopicsModal: TranslatedComponent<MoveTopicsModalProps> = ({
    toggleVisibility,
    substate,
    submit,
    trls,
    stage,
}) => {
    const { isMobile } = useBreakpoint();
    const { stages } = useSelectorNonNullable((state) => state.vacancyFunnel);
    const items: VacancyFunnelStageItem[] = useMemo(() => {
        const filteredOptions = stages?.filter((stage) => stage.state === substate.state);
        return (
            filteredOptions?.map((stage) => ({
                value: stage.funnelStageId.toString(),
                text: stage.substateName || trls[EmployerStateTrlKeys[stage.state]],
                stage,
            })) ?? []
        );
    }, [stages, trls, substate.state]);

    const [item, setItem] = useState<VacancyFunnelStageItem>(items[0]);

    const provider: DataProvider<VacancyFunnelStageItem> = useMemo(() => {
        return createDataProvider<VacancyFunnelStageItem>({
            debounceTimeout: 0,
            minCharsCount: 0,
            fetcher: decorateWithResultProducer(createStaticDataFetcher(items), (fetcherData) => {
                const sections: DataSection<VacancyFunnelStageItem>[] = [];
                const stage = fetcherData?.[0];
                if (!stage || !substate) {
                    return sections;
                }
                sections.push({ text: trls[TrlKeys.state], type: 'delimiter' });
                sections.push({
                    type: 'cells',
                    items: [{ value: stage.value, data: stage }],
                });
                if (stage.stage?.substates?.some((subst) => subst.funnelStageId !== substate.funnelStageId)) {
                    sections.push({ text: trls[TrlKeys.substate], type: 'delimiter' });
                    const subStateSection: DataSectionOfType<'cells', VacancyFunnelStageItem> = {
                        type: 'cells',
                        items: [],
                    };
                    stage.stage.substates
                        .filter((subst) => subst !== substate)
                        .forEach((subst) => {
                            subStateSection.items.push({
                                data: {
                                    text:
                                        subst.substateName ||
                                        (stage.stage?.state ? trls[EmployerStateTrlKeys[stage.stage.state]] : ''),
                                    value: subst.funnelStageId.toString(),
                                    substate: subst,
                                },
                                value: subst.funnelStageId.toString(),
                            });
                        });
                    sections.push(subStateSection);
                }
                return sections;
            }),
        });
    }, [items, substate, trls]);

    const content = (
        <div className={styles.wrapper}>
            <Card borderWidth="default" borderRadius={12} padding={16}>
                <Text typography="label-2-regular">{substate?.substateName}</Text>
            </Card>
            <ArrowRightOutlinedSize24 />
            <SelectFixedHeight>
                <Select
                    type="radio"
                    dataProvider={provider}
                    name="newState"
                    onChange={setItem}
                    value={item}
                    triggerProps={{
                        size: 'large',
                        label: trls[TrlKeys.newStatePlaceholder],
                        stretched: true,
                    }}
                />
            </SelectFixedHeight>
        </div>
    );

    const onClick = useCallback(() => {
        submit({
            topicTransferStageId: item.value,
            stageId: stage.funnelStageId,
            substate,
        });
        toggleVisibility();
    }, [item, stage.funnelStageId, submit, substate, toggleVisibility]);

    const button = (
        <Button mode="primary" style="accent" onClick={onClick}>
            {trls[TrlKeys.submit]}
        </Button>
    );

    return (
        <>
            <Modal
                actions={<Action mode="secondary" onClick={toggleVisibility} icon={CrossOutlinedSize24} />}
                visible={!isMobile && !!substate}
                onClose={toggleVisibility}
                titleSize="medium"
                title={trls[TrlKeys.title]}
                titleDescription={trls[TrlKeys.subtitle]}
                footer={<ActionBar primaryActions={button} />}
            >
                {content}
            </Modal>
            <BottomSheet
                visible={isMobile && !!substate}
                onClose={toggleVisibility}
                header={
                    <NavigationBar showDivider="always" title={trls[TrlKeys.title]} subtitle={trls[TrlKeys.subtitle]} />
                }
                footer={<BottomSheetFooter>{button}</BottomSheetFooter>}
            >
                {content}
            </BottomSheet>
        </>
    );
};

export default translation(MoveTopicsModal);
