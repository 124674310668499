import {
    AnonymityType,
    FormatType,
    ProcessedManagerTemplate,
    Template,
    TemplateType,
    STATE_TO_TEMPLATE_MAIL,
    STATE_TO_TEMPLATE_SMS,
} from 'src/models/employerMailTemplates';
import { EmployerStateType } from 'src/models/negotiationTopic.types';

export type StateToMailTemplateType = keyof typeof STATE_TO_TEMPLATE_MAIL;
export type StateToSmsTemplateType = keyof typeof STATE_TO_TEMPLATE_SMS;

const isValidTemplate = (template: Template, type: TemplateType | null): boolean => {
    // При запросе темплейтов с AnonymityType.COMMON, бэк также возвращает шаблоны с типом NONE,
    // а их нужно исключить
    if (template.anonymityType === AnonymityType.NONE) {
        return false;
    }
    // для некоторых employerState у нас есть шаблоны нескольких типов(type)
    // нам нужно убедиться, что выбираем нужный тип, исходя из данных воронки
    return template.type === type;
};

// Утилита подготавливает шаблоны для текстов и смс

export default (
    templates: Template[],
    employerState: EmployerStateType,
    employerManagerTemplateId: number | null,
    smsEmployerManagerTemplateId: number | null
): { mailTemplates: ProcessedManagerTemplate[] | []; smsTemplates: ProcessedManagerTemplate[] | [] } => {
    const result = templates.reduce(
        (acc: [ProcessedManagerTemplate[], ProcessedManagerTemplate[]], template) => {
            const processedTemplate = { ...template, isSelected: false };
            const isPrimaryTemplate = !!template.primaryTemplate;
            const mailTemplateType = STATE_TO_TEMPLATE_MAIL[employerState as StateToMailTemplateType];
            const smsTemplateType = STATE_TO_TEMPLATE_SMS[employerState as StateToSmsTemplateType];

            const isSmsTemplate = template.formatType === FormatType.SMS && isValidTemplate(template, smsTemplateType);

            const isMailTemplate =
                template.formatType === FormatType.MAIL && isValidTemplate(template, mailTemplateType);

            if (isMailTemplate) {
                acc[0].push({
                    ...processedTemplate,
                    isSelected:
                        template.id === employerManagerTemplateId || (!employerManagerTemplateId && isPrimaryTemplate),
                });
            }
            if (isSmsTemplate) {
                acc[1].push({
                    ...processedTemplate,
                    isSelected:
                        template.id === smsEmployerManagerTemplateId ||
                        (!smsEmployerManagerTemplateId && isPrimaryTemplate),
                });
            }
            return acc;
        },
        [[], []]
    );
    return { mailTemplates: result[0], smsTemplates: result[1] };
};
