import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { AxiosError } from 'axios';
import { FormApi } from 'final-form';

import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useNotification } from 'src/components/Notifications/Provider';
import NotificationComponent from 'src/components/VacancyFunnel/Notifications';
import StageSettingsModal from 'src/components/VacancyFunnel/StageSettingsModal';
import UpdateStateButton from 'src/components/VacancyFunnel/State/UpdateStateButton';
import { EmployerStateTrlKeys } from 'src/components/VacancyFunnel/trls';
import { FunnelErrorCodes, StageSettingsModalState } from 'src/components/VacancyFunnel/types';
import translation from 'src/components/translation';
import useFeatureEnabled from 'src/hooks/useFeatureEnabled';
import { useSelectorNonNullable } from 'src/hooks/useSelector';
import useToggleState from 'src/hooks/useToggleState';
import { StageSettingsModalFormData, updateVacancyFunnelStage, VacancyFunnelStage } from 'src/models/vacancyFunnel';
import fetcher from 'src/utils/fetcher';

interface UpdateStateTriggerProps {
    hideLabel?: boolean;
    stage: VacancyFunnelStage;
}

const Features = {
    newMailTemplatesEnabled: 'new_mail_templates_for_funnel_enabled',
};

const UPDATE_FUNNEL_STAGE_URL = '/shards/employer/vacancyresponses/funnel/update';

const UpdateStateTrigger: TranslatedComponent<UpdateStateTriggerProps> = ({ trls, hideLabel, stage }) => {
    const [isStageSettingsModalVisible, toggleStageSettingsModalVisible] = useToggleState(false);
    const funnelId = useSelectorNonNullable((state) => state.vacancyFunnel.funnelId);
    const dispatch = useDispatch();
    const { addNotification } = useNotification();
    const newMailTemplatesEnabled = useFeatureEnabled(Features.newMailTemplatesEnabled);

    const onUpdateStage = useCallback(
        async (data: StageSettingsModalFormData, form: FormApi<StageSettingsModalFormData>) => {
            try {
                const { addressChecked, quickTransferToStageEnabled, ...templateInfo } = data;
                const { sendSms, sendMessage } = templateInfo;

                // Если fromEmployerManagerTemplates=true значит шаблоны перенесены в базу шаблонов менеджера,
                // и в воронку текст шаблона не сохраняем, так как в этом случае берем текст не из воронки, а из шаблонов менеджера
                if ((newMailTemplatesEnabled && templateInfo.fromEmployerManagerTemplates) || !sendMessage) {
                    templateInfo.template = null;
                    templateInfo.smsTemplate = null;
                }

                const result = await fetcher.put(UPDATE_FUNNEL_STAGE_URL, {
                    funnelId: funnelId.toString(),
                    stageId: stage.funnelStageId.toString(),
                    quickTransferToStageEnabled,
                    substateName: null,
                    templateInfo: {
                        ...templateInfo,
                        sendSms: sendMessage && sendSms,
                        addressId: addressChecked && !data.useAddressFromVacancy ? data.addressId : null,
                    },
                });
                dispatch(updateVacancyFunnelStage(result.data));
                toggleStageSettingsModalVisible();
                form.reset();
                addNotification(NotificationComponent, {
                    props: {
                        status: trls[EmployerStateTrlKeys[stage.state]],
                        type: 'success',
                        actionType: 'updateStage',
                    },
                });
            } catch (e) {
                const err = e as AxiosError<{ message: string; code: FunnelErrorCodes } | void>;
                const isFunnelLockedError = err.response?.data?.code === FunnelErrorCodes.FunnelByIdLocked;
                addNotification(NotificationComponent, {
                    props: {
                        status: trls[EmployerStateTrlKeys[stage.state]],
                        type: 'error',
                        actionType: isFunnelLockedError ? 'funnelLockedError' : 'defaultError',
                    },
                });
            }
        },
        [
            addNotification,
            dispatch,
            funnelId,
            stage.funnelStageId,
            stage.state,
            toggleStageSettingsModalVisible,
            newMailTemplatesEnabled,
            trls,
        ]
    );

    return (
        <>
            <UpdateStateButton hideLabel={hideLabel} onClick={toggleStageSettingsModalVisible} />
            {isStageSettingsModalVisible && (
                <StageSettingsModal
                    formId={`employer-funnel-stage-update-${stage.funnelStageId}`}
                    modalType={StageSettingsModalState.StateEditing}
                    onSubmit={onUpdateStage}
                    subtitle={trls[EmployerStateTrlKeys[stage.state]]}
                    stage={stage}
                    isVisible={isStageSettingsModalVisible}
                    toggleVisibility={toggleStageSettingsModalVisible}
                />
            )}
        </>
    );
};

export default translation(UpdateStateTrigger);
