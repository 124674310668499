import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useBreakpoint } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useNotification } from 'src/components/Notifications/Provider';
import NotificationComponent from 'src/components/VacancyFunnel/Notifications';
import State from 'src/components/VacancyFunnel/State';
import Substate from 'src/components/VacancyFunnel/Substate';
import { EmployerStateTrlKeys } from 'src/components/VacancyFunnel/trls';
import isHidableState from 'src/components/VacancyFunnel/utils/isHidableStage';
import translation from 'src/components/translation';
import { useSelectorNonNullable } from 'src/hooks/useSelector';
import { updateSubstatesOrder } from 'src/models/candidatesList';
import { updateVacancyFunnelSubstatesOrder, VacancyFunnelStage } from 'src/models/vacancyFunnel';
import fetcher from 'src/utils/fetcher';

import AddSubstateTrigger from 'src/components/VacancyFunnel/StageList/Stage/AddSubstateTrigger';
import DeleteSubstateTrigger from 'src/components/VacancyFunnel/StageList/Stage/DeleteSubstateTrigger';
import StageVisibilityTrigger from 'src/components/VacancyFunnel/StageList/Stage/StageVisibilityTrigger';
import UpdateStateTrigger from 'src/components/VacancyFunnel/StageList/Stage/UpdateStateTrigger';
import UpdateSubstateTrigger from 'src/components/VacancyFunnel/StageList/Stage/UpdateSubstateTrigger';

interface StageProps {
    stage: VacancyFunnelStage;
    stageIndex: number;
    readOnly: boolean;
}

const UPDATE_FUNNEL_SUBSTATES_ORDER_URL = '/shards/employer/vacancyresponses/funnel/update_order';

declare global {
    interface FetcherPutApi {
        [UPDATE_FUNNEL_SUBSTATES_ORDER_URL]: {
            queryParams: void;
            body: {
                funnelId: string;
                stageId: string;
                substatesIds: number[];
            };
            response: { funnelStageIds: number[] };
        };
    }
}

const Stage: TranslatedComponent<StageProps> = ({ trls, stage, stageIndex, readOnly }) => {
    const dispatch = useDispatch();
    const funnelId = useSelectorNonNullable((state) => state.vacancyFunnel.funnelId);

    const { isXS, isGtM } = useBreakpoint();
    const { addNotification } = useNotification();
    const hideLabelsInControls = !isGtM;

    const handleUpdateSubstatesOrder = useCallback(
        async (targetItemIndex: number, dragItemIndex: number) => {
            const substates = stage?.substates;

            if (!substates) {
                return;
            }

            const updatedSubstates = [...substates];

            updatedSubstates.splice(dragItemIndex, 1);
            updatedSubstates.splice(targetItemIndex, 0, substates[dragItemIndex]);

            try {
                const result = await fetcher.put(UPDATE_FUNNEL_SUBSTATES_ORDER_URL, {
                    funnelId: funnelId.toString(),
                    stageId: stage.funnelStageId.toString(),
                    substatesIds: updatedSubstates.map((substage) => substage.funnelStageId),
                });

                dispatch(
                    updateVacancyFunnelSubstatesOrder({
                        funnelStageId: stage.funnelStageId,
                        funnelSubStateIds: result.data.funnelStageIds,
                    })
                );
                dispatch(
                    updateSubstatesOrder({
                        rootStageId: stage.state.toLowerCase(),
                        substatesIds: result.data.funnelStageIds,
                    })
                );

                addNotification(NotificationComponent, {
                    props: {
                        status: trls[EmployerStateTrlKeys[stage.state]],
                        type: 'success',
                        actionType: 'updateSubstatesOrder',
                    },
                });
            } catch (_) {
                addNotification(NotificationComponent, {
                    props: {
                        status: trls[EmployerStateTrlKeys[stage.state]],
                        type: 'error',
                        actionType: 'updateSubstatesOrderError',
                    },
                });
            }
        },
        [addNotification, dispatch, funnelId, stage, trls]
    );

    return (
        <State
            stateName={trls[EmployerStateTrlKeys[stage.state]]}
            addSubstateTrigger={!readOnly && <AddSubstateTrigger stage={stage} hideLabel={hideLabelsInControls} />}
            updateStateTrigger={!readOnly && <UpdateStateTrigger stage={stage} hideLabel={hideLabelsInControls} />}
            toggleVisibilityTrigger={
                isHidableState(stage.state) && <StageVisibilityTrigger stage={stage} hideLabel={hideLabelsInControls} />
            }
            hidden={stage.hidden}
        >
            {(dragAndDropProps) =>
                stage.substates?.map((substate, substateIndex) => (
                    <Substate
                        key={substate.funnelStageId}
                        stageIndex={stageIndex}
                        substateIndex={substateIndex}
                        deleteTrigger={
                            !readOnly && (
                                <DeleteSubstateTrigger
                                    hideLabel={hideLabelsInControls}
                                    stage={stage}
                                    substate={substate}
                                />
                            )
                        }
                        updateTrigger={
                            !readOnly && <UpdateSubstateTrigger hideLabel={hideLabelsInControls} substate={substate} />
                        }
                        draggable={!readOnly && !isXS}
                        readOnly={readOnly}
                        onDrop={handleUpdateSubstatesOrder}
                        hidden={stage.hidden}
                        {...dragAndDropProps}
                    >
                        {substate.substateName}
                    </Substate>
                ))
            }
        </State>
    );
};

export default translation(Stage);
