import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { AxiosError } from 'axios';
import { FormApi } from 'final-form';

import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { useNotification } from 'src/components/Notifications/Provider';
import NotificationComponent from 'src/components/VacancyFunnel/Notifications';
import StageSettingsModal from 'src/components/VacancyFunnel/StageSettingsModal';
import { EmployerStateTrlKeys } from 'src/components/VacancyFunnel/trls';
import { FunnelErrorCodes, StageSettingsModalState } from 'src/components/VacancyFunnel/types';
import translation from 'src/components/translation';
import useFeatureEnabled from 'src/hooks/useFeatureEnabled';
import { useSelectorNonNullable } from 'src/hooks/useSelector';
import { updateSubstates } from 'src/models/candidatesList';
import {
    createVacancyFunnelSubstate,
    StageSettingsModalFormData,
    TemplateInfo,
    updateVacancyFunnelStage,
    VacancyFunnelStage,
    VacancyFunnelSubstate,
} from 'src/models/vacancyFunnel';
import fetcher from 'src/utils/fetcher';

const TrlKeys = {
    substateAlreadyExists: 'employer.vacancyresponses.funnel.substateAlreadyExists',
};

const Features = {
    newMailTemplatesEnabled: 'new_mail_templates_for_funnel_enabled',
};

const CREATE_FUNNEL_STAGE_URL = '/shards/employer/vacancyresponses/funnel/create';
const UPDATE_FUNNEL_STAGE_URL = '/shards/employer/vacancyresponses/funnel/update';

declare global {
    interface FetcherPostApi {
        [CREATE_FUNNEL_STAGE_URL]: {
            queryParams: void;
            body: {
                funnelId: string;
                quickTransferToStageEnabled: boolean;
                stateName: string;
                substateName: string | null;
                templateInfo: TemplateInfo;
            };
            response: VacancyFunnelSubstate;
        };
    }
}

interface EmployerSubstateModalProps {
    visible: boolean;
    setOff: () => void;
    stage: VacancyFunnelStage | VacancyFunnelSubstate;
    mode: 'create' | 'update';
}

const EmployerSubstateModal: TranslatedComponent<EmployerSubstateModalProps> = ({
    trls,
    visible,
    setOff,
    stage,
    mode,
}) => {
    const isEdit = mode === 'update';
    const { addNotification } = useNotification();
    const funnelId = useSelectorNonNullable((state) => state.vacancyFunnel.funnelId);
    const dispatch = useDispatch();

    const newMailTemplatesEnabled = useFeatureEnabled(Features.newMailTemplatesEnabled);

    const onUpdateStage = useCallback(
        async (data: StageSettingsModalFormData, form: FormApi<StageSettingsModalFormData>) => {
            if (!stage) {
                return;
            }
            const { substateName, addressChecked, quickTransferToStageEnabled, ...templateInfo } = data;
            const { sendSms, sendMessage } = templateInfo;
            const isSubstate = !!substateName;

            // Если fromEmployerManagerTemplates=true значит шаблоны перенесены в базу шаблонов менеджера,
            // и в воронку текст шаблона не сохраняем, так как в этом случае берем текст не из воронки, а из шаблонов менеджера
            if ((newMailTemplatesEnabled && templateInfo.fromEmployerManagerTemplates) || !sendMessage) {
                templateInfo.template = null;
                templateInfo.smsTemplate = null;
            }

            try {
                const result = await fetcher.put(UPDATE_FUNNEL_STAGE_URL, {
                    funnelId: funnelId.toString(),
                    stageId: stage.funnelStageId.toString(),
                    quickTransferToStageEnabled,
                    templateInfo: {
                        ...templateInfo,
                        sendSms: sendMessage && sendSms,
                        addressId: addressChecked && !data.useAddressFromVacancy ? data.addressId : null,
                    },
                    substateName: substateName ? substateName.replaceAll(/\s/g, ' ').trim() : null,
                });
                setOff();
                form.restart();
                dispatch(updateVacancyFunnelStage(result.data));
                if (isSubstate) {
                    dispatch(
                        updateSubstates({
                            name: substateName,
                            id: `${result.data.state.toLowerCase()}_${result.data.funnelStageId}`,
                            parentId: result.data.state.toLowerCase(),
                            eventType: 'edit',
                        })
                    );
                }
                addNotification(NotificationComponent, {
                    props: {
                        actionType: isSubstate ? 'updateSubstate' : 'updateStage',
                        status: isSubstate ? substateName : trls[EmployerStateTrlKeys[stage.state]],
                        type: 'success',
                    },
                });
            } catch (e) {
                const err = e as AxiosError<{ message: string; code: FunnelErrorCodes } | void>;
                const isFunnelLockedError = err.response?.data?.code === FunnelErrorCodes.FunnelByIdLocked;
                addNotification(NotificationComponent, {
                    props: {
                        status: substateName || '',
                        actionType: isFunnelLockedError ? 'funnelLockedError' : 'defaultError',
                        type: 'error',
                    },
                });
            }
        },
        [addNotification, dispatch, funnelId, newMailTemplatesEnabled, setOff, stage, trls]
    );

    const onCreateSubstate = useCallback(
        // eslint-disable-next-line consistent-return
        async (data: StageSettingsModalFormData, form: FormApi<StageSettingsModalFormData>) => {
            const { substateName, addressChecked, quickTransferToStageEnabled, ...templateInfo } = data;

            const { sendSms, sendMessage } = templateInfo;

            // Если fromEmployerManagerTemplates=true значит шаблоны перенесены в базу шаблонов менеджера,
            // и в воронку текст шаблона не сохраняем, так как в этом случае берем текст не из воронки, а из шаблонов менеджера
            if ((newMailTemplatesEnabled && templateInfo.fromEmployerManagerTemplates) || !sendMessage) {
                templateInfo.template = null;
                templateInfo.smsTemplate = null;
            }

            try {
                const result = await fetcher.post(CREATE_FUNNEL_STAGE_URL, {
                    funnelId: funnelId.toString(),
                    stateName: stage.state,
                    substateName: substateName ? substateName.replaceAll(/\s/g, ' ').trim() : null,
                    quickTransferToStageEnabled,
                    templateInfo: {
                        ...templateInfo,
                        addressId: addressChecked && !data.useAddressFromVacancy ? data.addressId : null,
                        sendSms: sendMessage && sendSms,
                    },
                });
                dispatch(createVacancyFunnelSubstate(result.data));
                dispatch(
                    updateSubstates({
                        name: result.data.substateName,
                        id: `${result.data.state.toLowerCase()}_${result.data.funnelStageId}`,
                        eventType: 'add',
                        parentId: result.data.state.toLowerCase(),
                    })
                );
                setOff();
                form.restart();
                addNotification(NotificationComponent, {
                    props: {
                        status: substateName || '',
                        actionType: 'createSubstate',
                        type: 'success',
                    },
                });
            } catch (e) {
                const err = e as AxiosError<{ message: string; code: FunnelErrorCodes } | void>;
                const errorCode = err.response?.data?.code;
                if (errorCode === FunnelErrorCodes.FunnelSubstateNameTaken) {
                    return {
                        substateName: trls[TrlKeys.substateAlreadyExists],
                    };
                }
                const isFunnelLockedError = errorCode === FunnelErrorCodes.FunnelByIdLocked;
                addNotification(NotificationComponent, {
                    props: {
                        status: substateName || '',
                        actionType: isFunnelLockedError ? 'funnelLockedError' : 'defaultError',
                        type: 'error',
                    },
                });
            }
        },
        [addNotification, dispatch, funnelId, newMailTemplatesEnabled, setOff, stage.state, trls]
    );

    if (!stage || !visible) {
        return null;
    }

    return (
        <StageSettingsModal
            formId={`employer-funnel-stage-${isEdit ? 'edit' : 'create'}-substate-${stage.funnelStageId}`}
            modalType={isEdit ? StageSettingsModalState.SubstateEditing : StageSettingsModalState.SubstateCreating}
            stage={stage}
            isVisible={visible}
            toggleVisibility={setOff}
            onSubmit={isEdit ? onUpdateStage : onCreateSubstate}
        />
    );
};

export default translation(EmployerSubstateModal);
