import { FC } from 'react';

import EmployerSubstateModal from 'src/components/VacancyFunnel/EmployerSubstateModal';
import UpdateSubstateButton from 'src/components/VacancyFunnel/Substate/UpdateSubstateButton';
import useOnOffState from 'src/hooks/useOnOffState';
import { VacancyFunnelSubstate } from 'src/models/vacancyFunnel';

interface UpdateSubstateTriggerProps {
    hideLabel?: boolean;
    substate: VacancyFunnelSubstate;
}

const UpdateSubstateTrigger: FC<UpdateSubstateTriggerProps> = ({ hideLabel, substate }) => {
    const [isModalVisible, setOn, setOff] = useOnOffState(false);
    return (
        <>
            <UpdateSubstateButton hideLabel={hideLabel} onClick={setOn} />
            {isModalVisible && (
                <EmployerSubstateModal visible={isModalVisible} setOff={setOff} stage={substate} mode="update" />
            )}
        </>
    );
};

export default UpdateSubstateTrigger;
