import { CollectionName, CollectionNameType } from 'src/models/employer/vacancyresponses/collections.types';
import { EmployerStateType } from 'src/models/negotiationTopic.types';

const EMPLOYER_STATE_TO_COLLECTION_NAME_MAP = new Map<EmployerStateType, CollectionNameType>([
    [EmployerStateType.Response, CollectionName.Response],
    [EmployerStateType.Consider, CollectionName.Consider],
    [EmployerStateType.PhoneInterview, CollectionName.PhoneInterview],
    [EmployerStateType.Assessment, CollectionName.Assessment],
    [EmployerStateType.Interview, CollectionName.Interview],
    [EmployerStateType.Invitation, CollectionName.Interview],
    [EmployerStateType.Offer, CollectionName.Offer],
    [EmployerStateType.Hired, CollectionName.Hired],
    [EmployerStateType.DiscardByEmployer, CollectionName.DiscardByEmployer],
    [EmployerStateType.DiscardByApplicant, CollectionName.DiscardByApplicant],
    [EmployerStateType.DiscardNoInteraction, CollectionName.DiscardNoInteraction],
    [EmployerStateType.DiscardVacancyClosed, CollectionName.DiscardVacancyClosed],
    [EmployerStateType.DiscardToOtherVacancy, CollectionName.DiscardToOtherVacancy],
]);

const getCollectionNameByEmployerState = (state: EmployerStateType): CollectionNameType | undefined =>
    EMPLOYER_STATE_TO_COLLECTION_NAME_MAP.get(state);

export default getCollectionNameByEmployerState;
