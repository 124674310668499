import { LangTrls } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import { EmployerStateType } from 'src/models/negotiationTopic.types';

type ConditionKey = 'default' | 'hasResponses' | 'hasSubstates' | 'hasResponses.hasSubstates';

interface TrlKeys {
    state: Record<EmployerStateType, string>;
    message: Record<ConditionKey, string>;
}

export interface Options {
    state: EmployerStateType;
    hasResponses?: boolean;
    hasSubstates?: boolean;
    trls: LangTrls;
    TrlKeys: TrlKeys;
}

const getMessageTrlKey = ({ hasSubstates, hasResponses, TrlKeys }: Omit<Options, 'state' | 'trls'>): string => {
    if (hasResponses && hasSubstates) {
        return TrlKeys.message['hasResponses.hasSubstates'];
    }
    if (hasResponses) {
        return TrlKeys.message.hasResponses;
    }
    if (hasSubstates) {
        return TrlKeys.message.hasSubstates;
    }
    return TrlKeys.message.default;
};

const getMessage = ({ state, hasSubstates, hasResponses, trls, TrlKeys }: Options): string => {
    return format(trls[getMessageTrlKey({ hasSubstates, hasResponses, TrlKeys })], {
        '{0}': trls[TrlKeys.state[state]],
    });
};

export default getMessage;
