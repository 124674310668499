import { FC, useRef } from 'react';
import { FieldInputProps, FieldMetaState } from 'react-final-form';

import { TextArea } from '@hh.ru/magritte-ui';

import { VariableType } from 'src/models/employerMailTemplates';

import Variables from 'src/components/Employer/TextareaWithVariables/Variables';

interface TextareaWithVariablesProps {
    input: FieldInputProps<string>;
    meta: FieldMetaState<string>;
    variables: VariableType[];
    fieldName: string;
    placeholder: string;
}

const TextareaWithVariables: FC<TextareaWithVariablesProps> = ({ input, meta, variables, fieldName, placeholder }) => {
    const textareaRef = useRef<HTMLTextAreaElement>(null);
    return (
        <>
            <TextArea
                layout="hug"
                value={input.value || ''}
                onChange={input.onChange}
                onBlur={input.onBlur}
                onFocus={input.onFocus}
                elevatePlaceholder
                placeholder={placeholder}
                invalid={meta.touched && meta.invalid}
                errorMessage={meta.error as string}
                ref={textareaRef}
            />
            <Variables variables={variables} fieldName={fieldName} textareRef={textareaRef} />
        </>
    );
};

export default TextareaWithVariables;
