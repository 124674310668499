import { Button } from '@hh.ru/magritte-ui';
import { TrashOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

interface DeleteSubstateButtonProps {
    hideLabel?: boolean;
    onClick: () => void;
    disabled?: boolean;
}

const TrlKeys = {
    removeSubstate: 'employer.vacancyresponses.funnel.removeSubstate',
};

const DeleteSubstateButton: TranslatedComponent<DeleteSubstateButtonProps> = ({
    trls,
    hideLabel,
    onClick,
    disabled,
}) => (
    <Button
        size="small"
        style="neutral"
        mode="tertiary"
        onClick={onClick}
        icon={<TrashOutlinedSize16 />}
        disabled={disabled}
        hideLabel={hideLabel}
    >
        {trls[TrlKeys.removeSubstate]}
    </Button>
);

export default translation(DeleteSubstateButton);
