import { ReactNode } from 'react';

import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import StageCell from 'src/components/VacancyFunnel/StageCell';
import { DragAndDropState } from 'src/components/VacancyFunnel/hooks/useDragAndDrop';
import translation from 'src/components/translation';

const TrlKeys = {
    dropPlaceholderText: 'employer.vacancyresponses.funnel.updateSubstatesOrder.dropPlaceholderText',
};

export interface SubstateProps extends DragAndDropState {
    children: string;
    stageIndex: number;
    substateIndex: number;
    draggable?: boolean;
    deleteTrigger?: ReactNode;
    updateTrigger?: ReactNode;
    onDrop?: (targetItemIndex: number, dragItemIndex: number) => void;
    hidden?: boolean;
    readOnly?: boolean;
}

const Substate: TranslatedComponent<SubstateProps> = ({
    trls,
    children,
    stageIndex,
    substateIndex,
    deleteTrigger,
    updateTrigger,
    draggable,
    dragDirection,
    dragItemIndex,
    targetItemIndex,
    handleDragStart,
    handleDragEnd,
    handleDragEnter,
    handleDrop,
    handleDragOver,
    onDrop,
    hidden,
    readOnly = false,
}) => {
    const isTarget = targetItemIndex === substateIndex;
    const isDragging = dragItemIndex === substateIndex;
    const dropPlaceholder = <StageCell isDropPlaceholder>{trls[TrlKeys.dropPlaceholderText]}</StageCell>;

    return (
        <div
            onDragStart={() => handleDragStart(substateIndex)}
            onDragEnd={handleDragEnd}
            onDragEnter={(event) => handleDragEnter(event, substateIndex)}
            onDrop={(event) => handleDrop(event, stageIndex, onDrop)}
            onDragOver={handleDragOver}
            draggable={!hidden && draggable}
        >
            {isTarget && dragDirection === 'up' && dropPlaceholder}
            <StageCell
                actions={
                    <>
                        {!hidden && deleteTrigger}
                        {!hidden && updateTrigger}
                    </>
                }
                draggable={draggable}
                isDragging={isDragging}
                disabled={hidden}
                readOnly={readOnly}
            >
                {children}
            </StageCell>
            {isTarget && dragDirection === 'down' && dropPlaceholder}
        </div>
    );
};

export default translation(Substate);
