import { useSelector } from 'src/hooks/useSelector';

interface AutomationToolsState {
    autoActionEnabled: boolean;
    autoInviteEnabled: boolean;
    hasAutomationTools: boolean;
}

const useHasAutomationTools = (): AutomationToolsState => {
    const autoActionEnabled = useSelector((state) => !!state.vacancyResponseFlags.autoActionEnabled);
    const autoInviteEnabled = useSelector((state) => !!state.autoInvite && !state.autoInvite.disableTime);

    return {
        autoActionEnabled,
        autoInviteEnabled,
        hasAutomationTools: autoActionEnabled || autoInviteEnabled,
    };
};

export default useHasAutomationTools;
