import { useCallback } from 'react';
import { Field as FinalField, useField } from 'react-final-form';

import { Cell, CellText, Checkbox, TextArea, VSpacing } from '@hh.ru/magritte-ui';
import format from 'bloko/common/format';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import TextareaWithVariables from 'src/components/Employer/TextareaWithVariables';
import TemplatesSelect from 'src/components/VacancyFunnel/TemplatesSelect';
import { EmployerStateTrlKeys } from 'src/components/VacancyFunnel/trls';
import translation from 'src/components/translation';
import useFeatureEnabled from 'src/hooks/useFeatureEnabled';
import { VariableType, ProcessedManagerTemplate } from 'src/models/employerMailTemplates';
import { EmployerStateType } from 'src/models/negotiationTopic.types';

import MailTemplateDescription from 'src/components/VacancyFunnel/StageSettingsModal/TemplateField/MailTemplateDescription';

const FIELD_NAME = 'template';
const CHECKBOX_FIELD_NAME = 'sendMessage';
const QUICK_TRANSFER_FIELD_NAME = 'quickTransferToStageEnabled';

interface TemplateFieldProps {
    isSubstate?: boolean;
    variables: VariableType[];
    stateName: EmployerStateType;
    mailTemplates: ProcessedManagerTemplate[];
    fallbackTemplate: string;
    fallbackTemplateId: number | null;
    linkToEditTemplate: string;
    linkToCreateTemplate: string;
    closeModal: VoidFunction;
}

const TrlKeys = {
    messagePlaceholder: 'employer.vacancyresponses.funnel.stateSettingsModal.messagePlaceholder',
    required: 'employer.vacancyresponses.funnel.stateSettingsModal.required',
    sendMessage: 'employer.vacancyresponses.funnel.stateSettingsModal.sendMessage',
    sendMessageSubtitle: 'employer.vacancyresponses.funnel.stateSettingsModal.sendMessageSubtitle',
    noMessageHint: 'employer.vacancyresponses.funnel.stateSettingsModal.noMessageHint',
    messageNeededForQuickTransfer: 'employer.vacancyresponses.funnel.stateSettingsModal.messageNeededForQuickTransfer',
};

const Features = {
    newMailTemplatesEnabled: 'new_mail_templates_for_funnel_enabled',
};

const TemplateField: TranslatedComponent<TemplateFieldProps> = ({
    trls,
    variables,
    isSubstate,
    stateName,
    mailTemplates,
    fallbackTemplate,
    fallbackTemplateId,
    linkToEditTemplate,
    linkToCreateTemplate,
    closeModal,
}) => {
    const newMailTemplatesEnabled = useFeatureEnabled(Features.newMailTemplatesEnabled);

    const isChecked = useField<boolean>(CHECKBOX_FIELD_NAME).input.value;
    const isQuickTransferEnabled = useField<boolean>(QUICK_TRANSFER_FIELD_NAME).input.value;
    const required = useCallback(
        (value: string) => {
            return !value && isChecked ? trls[TrlKeys.required] : undefined;
        },
        [isChecked, trls]
    );

    return (
        <>
            {isSubstate && (
                <FinalField<boolean> name={CHECKBOX_FIELD_NAME} type="checkbox">
                    {({ input: { value, ...restInput } }) => (
                        <>
                            <Cell align="top" left={<Checkbox {...restInput} />}>
                                <CellText>{trls[TrlKeys.sendMessage]}</CellText>
                                {!isChecked &&
                                    (isQuickTransferEnabled ? (
                                        <CellText type="subtitle" style="negative">
                                            {format(trls[TrlKeys.messageNeededForQuickTransfer], {
                                                '{0}': trls[EmployerStateTrlKeys[stateName]],
                                            })}
                                        </CellText>
                                    ) : (
                                        <CellText type="subtitle">
                                            {format(trls[TrlKeys.noMessageHint], {
                                                '{0}': trls[EmployerStateTrlKeys[stateName]],
                                            })}
                                        </CellText>
                                    ))}
                            </Cell>
                            {restInput.checked && <VSpacing default={16} />}
                        </>
                    )}
                </FinalField>
            )}
            {isChecked && (
                <>
                    {mailTemplates.length > 1 && newMailTemplatesEnabled && (
                        <TemplatesSelect
                            templates={mailTemplates}
                            fallbackTemplate={fallbackTemplate}
                            fallbackTemplateId={fallbackTemplateId}
                        />
                    )}

                    <FinalField<string> name={FIELD_NAME} validate={required}>
                        {({ input, meta }) => {
                            return !!mailTemplates.length && newMailTemplatesEnabled ? (
                                <>
                                    <TextArea
                                        layout="hug"
                                        value={input.value}
                                        onChange={input.onChange}
                                        onBlur={input.onBlur}
                                        onFocus={input.onFocus}
                                        elevatePlaceholder
                                        placeholder={trls[TrlKeys.messagePlaceholder]}
                                        invalid={meta.touched && meta.invalid}
                                        errorMessage={meta.error as string}
                                        disabled
                                    />
                                    <VSpacing default={8} />

                                    <MailTemplateDescription
                                        employerState={stateName}
                                        linkToEditTemplate={linkToEditTemplate}
                                        linkToCreateTemplate={linkToCreateTemplate}
                                        closeModal={closeModal}
                                    />
                                </>
                            ) : (
                                <TextareaWithVariables
                                    input={input}
                                    meta={meta}
                                    variables={variables}
                                    fieldName={FIELD_NAME}
                                    placeholder={trls[TrlKeys.messagePlaceholder]}
                                />
                            );
                        }}
                    </FinalField>
                </>
            )}
        </>
    );
};

export default translation(TemplateField);
