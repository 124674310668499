import { FC, useMemo } from 'react';

import { StageType } from 'src/components/VacancyFunnel/types';
import { useSelectorNonNullable } from 'src/hooks/useSelector';
import { isDiscardEmployerState } from 'src/models/negotiationTopic.types';

import Stage from 'src/components/VacancyFunnel/StageList/Stage';

interface StageListProps {
    activeSection: StageType;
    readOnly?: boolean;
}

const StageList: FC<StageListProps> = ({ activeSection, readOnly = false }) => {
    const { stages } = useSelectorNonNullable((state) => state.vacancyFunnel);
    const filteredStages = useMemo(() => {
        const isDiscard = activeSection === 'discard';
        return (stages || []).filter((stage) => {
            const isDiscardState = isDiscardEmployerState(stage.state);
            return isDiscard ? isDiscardState : !isDiscardState;
        });
    }, [activeSection, stages]);

    return (
        <>
            {filteredStages.map((stage, stageIndex) => (
                <Stage key={stage.funnelStageId} stage={stage} stageIndex={stageIndex} readOnly={readOnly} />
            ))}
        </>
    );
};

export default StageList;
