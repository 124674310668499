import { FC, MutableRefObject } from 'react';
import { useField } from 'react-final-form';

import { ChipsContainer, CustomChip, VSpacing } from '@hh.ru/magritte-ui';

import { useInsertValueToTextarea } from 'src/hooks/useInsertValueToTextarea';
import { VariableType } from 'src/models/employerMailTemplates';

interface VariablesProps {
    variables: VariableType[];
    fieldName: string;
    textareRef: MutableRefObject<HTMLTextAreaElement | null>;
}

const Variables: FC<VariablesProps> = ({ variables, fieldName, textareRef }) => {
    const { input } = useField<string>(fieldName, { subscription: { value: true } });
    const { value, onChange } = input;
    const insertValue = useInsertValueToTextarea(textareRef, value, onChange);

    return (
        <>
            <VSpacing default={12} />
            <ChipsContainer>
                {variables.map((variable) => (
                    <CustomChip key={variable.name} onClick={() => insertValue(variable.name)}>
                        {variable.translation}
                    </CustomChip>
                ))}
            </ChipsContainer>
        </>
    );
};

export default Variables;
