import { EmployerStateType } from 'src/models/negotiationTopic.types';

export const EmployerStateTrlKeys = {
    [EmployerStateType.DiscardByApplicant]: 'negotiations.states.discardByApplicant',
    [EmployerStateType.DiscardByEmployer]: 'negotiations.states.discardByEmployer.softForm',
    [EmployerStateType.DiscardNoInteraction]: 'negotiations.states.discardNoInteraction',
    [EmployerStateType.DiscardToOtherVacancy]: 'negotiations.states.discardToOtherVacancy',
    [EmployerStateType.DiscardVacancyClosed]: 'negotiations.states.discardVacancyClosed',
    [EmployerStateType.Assessment]: 'negotiations.states.assessment',
    [EmployerStateType.Consider]: 'negotiations.states.consider',
    [EmployerStateType.Hired]: 'negotiations.states.hired',
    [EmployerStateType.Interview]: 'negotiations.states.interview',
    [EmployerStateType.Invitation]: 'negotiations.states.invitation',
    [EmployerStateType.Offer]: 'negotiations.states.offer',
    [EmployerStateType.PhoneInterview]: 'negotiations.states.phone_interview',
    [EmployerStateType.Response]: 'negotiations.states.response',
};
