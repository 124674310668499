import { Alert, Button } from '@hh.ru/magritte-ui';
import { InfoCircleFilledSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

const TrlKeys = {
    title: 'employer.vacancyresponses.funnel.stage.hideAlert.title',
    description: {
        'hasVacancyContext.hasSubstates.hasAutomationTools':
            'employer.vacancyresponses.funnel.stage.hideAlert.description.withVacancyContextAndSubstatesAndAutomationTolls',
        'hasVacancyContext.hasSubstates':
            'employer.vacancyresponses.funnel.stage.hideAlert.description.withVacancyContextAndSubstates',
        'hasVacancyContext.hasAutomationTools':
            'employer.vacancyresponses.funnel.stage.hideAlert.description.withVacancyContextAndAutomationTolls',
        hasVacancyContext: 'employer.vacancyresponses.funnel.stage.hideAlert.description.withVacancyContext',
        hasSubstates: 'employer.vacancyresponses.funnel.stage.hideAlert.description.withSubstates',
        default: 'employer.vacancyresponses.funnel.stage.hideAlert.description.default',
    },
    confirmButtonText: 'employer.vacancyresponses.funnel.stage.hideAlert.confirmButtonText',
    cancelButtonText: 'Cancel',
};

interface StageVisibilityAlertProps {
    visible: boolean;
    loading: boolean;
    onConfirm: VoidFunction;
    onCancel: VoidFunction;
    hasVacancyContext?: boolean;
    hasSubstates?: boolean;
    hasAutomationTools?: boolean;
}

const getDescriptionTrlKey = ({
    hasVacancyContext,
    hasSubstates,
    hasAutomationTools,
}: Pick<StageVisibilityAlertProps, 'hasVacancyContext' | 'hasSubstates' | 'hasAutomationTools'>) => {
    if (hasVacancyContext && hasSubstates && hasAutomationTools) {
        return TrlKeys.description['hasVacancyContext.hasSubstates.hasAutomationTools'];
    }
    if (hasVacancyContext && hasSubstates) {
        return TrlKeys.description['hasVacancyContext.hasSubstates'];
    }
    if (hasVacancyContext && hasAutomationTools) {
        return TrlKeys.description['hasVacancyContext.hasAutomationTools'];
    }
    if (hasVacancyContext) {
        return TrlKeys.description.hasVacancyContext;
    }
    if (hasSubstates) {
        return TrlKeys.description.hasSubstates;
    }
    return TrlKeys.description.default;
};

const StageVisibilityAlert: TranslatedComponent<StageVisibilityAlertProps> = ({
    trls,
    visible,
    loading,
    onConfirm,
    onCancel,
    hasVacancyContext,
    hasSubstates,
    hasAutomationTools,
}) => (
    <Alert
        layout="vertical"
        visible={visible}
        icon={<InfoCircleFilledSize24 />}
        title={trls[TrlKeys.title]}
        description={trls[getDescriptionTrlKey({ hasVacancyContext, hasSubstates, hasAutomationTools })]}
        buttons={
            <>
                <Button mode="primary" style="accent" onClick={onConfirm} loading={loading}>
                    {trls[TrlKeys.confirmButtonText]}
                </Button>
                <Button mode="secondary" style="accent" onClick={onCancel}>
                    {trls[TrlKeys.cancelButtonText]}
                </Button>
            </>
        }
        onClose={onCancel}
    />
);

export default translation(StageVisibilityAlert);
