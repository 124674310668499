import { useMemo } from 'react';

import { useSelector } from 'src/hooks/useSelector';
import { CollectionNameType } from 'src/models/employer/vacancyresponses/collections.types';

type UseHasResponsesHook = (collectionName?: CollectionNameType) => boolean;

const useHasResponses: UseHasResponsesHook = (collectionName) => {
    const collections = useSelector((state) => state.candidatesList?.collections);

    return useMemo(
        () =>
            collectionName && collections
                ? !!collections.find(({ name }) => name === collectionName)?.collectionItemCount.total
                : false,
        [collectionName, collections]
    );
};

export default useHasResponses;
