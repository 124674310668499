import { useCallback, useRef } from 'react';

import { BottomSheet, BottomSheetFooter, Button, Title, Tooltip } from '@hh.ru/magritte-ui';
import { PlusOutlinedSize16 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import useToggleState from 'src/hooks/useToggleState';

interface AddSubstateButtonProps {
    hideLabel?: boolean;
    substatesCount: number;
    onClick: () => void;
    disabled?: boolean;
}

const TrlKeys = {
    addSubstate: 'employer.vacancyresponses.funnel.addSubstate',
    stageLimitExceeded: 'employer.vacancyresponses.funnel.stageLimitExceeded',
    confirm: 'employer.vacancyresponses.funnel.confirm',
};

const SUBSTATES_LIMIT = 15;

const AddSubstateButton: TranslatedComponent<AddSubstateButtonProps> = ({
    trls,
    substatesCount,
    hideLabel,
    onClick,
    disabled,
}) => {
    const [hintVisible, toggleHintVisible, setHintVisible] = useToggleState(false);
    const isStageLimitExceed = substatesCount === SUBSTATES_LIMIT;
    const buttonRef = useRef<HTMLButtonElement>(null);

    const handleClick = useCallback(() => {
        if (isStageLimitExceed) {
            toggleHintVisible();
        } else {
            onClick();
        }
    }, [isStageLimitExceed, onClick, toggleHintVisible]);

    return (
        <>
            <Button
                ref={buttonRef}
                size="small"
                style="neutral"
                mode="tertiary"
                onClick={handleClick}
                icon={<PlusOutlinedSize16 />}
                disabled={disabled}
                hideLabel={hideLabel}
            >
                {trls[TrlKeys.addSubstate]}
            </Button>
            <Tooltip
                placement="right-top"
                activatorRef={buttonRef}
                visible={hintVisible}
                onClose={() => setHintVisible(false)}
                showClose
                aria-label-close={trls[TrlKeys.confirm]}
            >
                {trls[TrlKeys.stageLimitExceeded]}
            </Tooltip>
            <BottomSheet
                visible={hintVisible}
                onClose={() => setHintVisible(false)}
                footer={
                    <BottomSheetFooter>
                        <Button mode="primary" style="accent" onClick={() => setHintVisible(false)} stretched>
                            {trls[TrlKeys.confirm]}
                        </Button>
                    </BottomSheetFooter>
                }
            >
                <Title Element="h2" size="medium" alignment="center" description={trls[TrlKeys.stageLimitExceeded]}>
                    {trls[TrlKeys.addSubstate]}
                </Title>
            </BottomSheet>
        </>
    );
};

export default translation(AddSubstateButton);
