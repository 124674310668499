export enum StageSettingsModalState {
    SubstateCreating = 'substateCreating',
    SubstateEditing = 'substateEditing',
    StateEditing = 'stateEditing',
}

export enum FunnelErrorCodes {
    FunnelByIdLocked = 'FUNNEL_BY_ID_LOCKED',
    CannotDeleteFunnelStageWithTopics = 'CAN_NOT_DELETE_FUNNEL_STAGE_WITH_TOPICS',
    FunnelSubstateNameTaken = 'FUNNEL_SUBSTATE_NAME_TAKEN',
}

export const STAGE_TYPES = ['inProgress', 'discard'] as const;

export type StageType = (typeof STAGE_TYPES)[number];
