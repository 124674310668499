import { FC, ReactNode } from 'react';
import classname from 'classnames';

import { Cell, CellText } from '@hh.ru/magritte-ui';
import { ArrowDownRightOutlinedSize16, DotsOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

import styles from './styles.less';

interface StageCellProps {
    children: string;
    tags?: ReactNode;
    actions?: ReactNode;
    draggable?: boolean;
    isDragging?: boolean;
    disabled?: boolean;
    isDropPlaceholder?: never;
    readOnly?: boolean;
}

interface DropPlaceholderCellProps {
    children: string;
    isDropPlaceholder?: boolean;
    tags?: never;
    actions?: never;
    draggable?: never;
    isDragging?: never;
    disabled?: never;
    readOnly?: boolean;
}

const StageCell: FC<StageCellProps | DropPlaceholderCellProps> = ({
    children,
    tags,
    actions,
    draggable,
    isDragging,
    isDropPlaceholder,
    disabled,
    readOnly = false,
}) => {
    return (
        <div
            className={classname(styles.container, {
                [styles.dragOver]: isDropPlaceholder,
                [styles.dragging]: isDragging,
            })}
        >
            <div>
                <Cell
                    vertPadding
                    disabled={disabled}
                    {...(draggable && { left: <DotsOutlinedSize24 cursor={!disabled ? 'grab' : undefined} /> })}
                    {...(readOnly && { left: <ArrowDownRightOutlinedSize16 /> })}
                >
                    <CellText maxLines={1}>{children}</CellText>
                </Cell>
            </div>
            <div className={styles.tagsWrapper}>{tags}</div>
            {actions && <div className={styles.actionsWrapper}>{actions}</div>}
        </div>
    );
};

export default StageCell;
