import { CheckCircleFilledSize24, MinusCircleFilledSize24 } from '@hh.ru/magritte-ui/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import Notification from 'src/components/Notifications/Notification';
import { NotificationProps as BaseProps } from 'src/components/Notifications/Provider/types';
import translation from 'src/components/translation';

type ActionType =
    | 'createSubstate'
    | 'defaultError'
    | 'deleteSubstate'
    | 'updateStage'
    | 'updateSubstate'
    | 'updateSubstatesOrder'
    | 'updateSubstatesOrderError'
    | 'funnelLockedError';

const TrlKeys = {
    defaultError: 'employer.vacancyresponses.funnel.stateSettingsModal.defaultError',
    createSubstate: 'employer.vacancyresponses.funnel.stateSettingsModal.createSubstate.success',
    updateStage: 'employer.vacancyresponses.funnel.stateSettingsModal.updateStage.success',
    updateSubstate: 'employer.vacancyresponses.funnel.stateSettingsModal.updateSubstate.success',
    deleteSubstate: 'employer.vacancyresponses.funnel.stateSettingsModal.deleteSubstate.success',
    updateSubstatesOrder: 'employer.vacancyresponses.funnel.updateSubstatesOrder.success',
    updateSubstatesOrderError: 'employer.vacancyresponses.funnel.updateSubstatesOrder.error',
    funnelLockedError: 'employer.vacancyresponses.funnel.funnelLockedError',
};

export interface NotificationProps extends BaseProps {
    status: string;
    type: 'error' | 'success';
    actionType: ActionType;
}

const NotificationComponent: TranslatedComponent<NotificationProps> = ({
    status,
    trls,
    type,
    actionType,
    ...restProps
}) => {
    const isError = type === 'error';
    const addon = isError ? (
        <MinusCircleFilledSize24 initialColor="negative" />
    ) : (
        <CheckCircleFilledSize24 initialColor="positive" />
    );
    return (
        <Notification addon={addon} autohideTime={3000} {...restProps}>
            {formatToReactComponent(trls[TrlKeys[actionType]], { '{0}': status })}
        </Notification>
    );
};

export default translation(NotificationComponent);
